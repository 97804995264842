import { createContext, useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthContext } from '../..';
import BuyerListStore from '../../store/buyer-list-store';
import Orders from './Orders';
import Order from './Order';

const buyerListStore = new BuyerListStore();

export const BuyerListContext = createContext({ buyerListStore });
const initBListLoad = async () => {
    try {
        await buyerListStore.load();
    } catch (e) {
        console.warn(e);
    }
};
initBListLoad();

const OrderRoutes = () => {
    const { authStore } = useContext(AuthContext);

    useEffect(() => {
        if (authStore.user) {
            initBListLoad();
        }
    }, [authStore.user]);

    return (
        <BuyerListContext.Provider value={{ buyerListStore }}>
            <Routes>
                <Route path="/" element={<Orders />} />
                <Route path="/:id" element={<Order />} />
            </Routes>
        </BuyerListContext.Provider>
    );
};

export default OrderRoutes;
