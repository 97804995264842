import { useContext, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { observer } from 'mobx-react-lite';

import { AuthContext } from './index';
import Loading from './components/Loading';
import ContentRoot from './components/ContentRoot/ContentRoot';
import User from './components/User';
import { theme } from './theme/theme';

const App = () => {
    const { authStore } = useContext(AuthContext);

    useEffect(() => {
        //при запуске  приложения если есть cookies пробуем авторизоваться.
        if (localStorage.getItem('token')) authStore.refresh();
    }, []);

    if (authStore.loading) return <Loading />;

    const isLogin = authStore.user !== null;

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider adapterLocale={'ru'} dateAdapter={AdapterLuxon}>
                {isLogin ? <ContentRoot /> : <User />}
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default observer(App);
