import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { SnackbarProvider } from 'notistack';

import './index.css';
import App from './App';
import AuthStore from './store/authStore';

const authStore = new AuthStore();

export const AuthContext = createContext({ authStore });

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <SnackbarProvider maxSnack={5} autoHideDuration={6000}>
            <AuthContext.Provider value={{ authStore }}>
                <App />
            </AuthContext.Provider>
        </SnackbarProvider>
    </React.StrictMode>
);
