import { useSnackbar } from 'notistack';

const useErrorHandler = () => {
    const { enqueueSnackbar } = useSnackbar();

    return (error) => {
        console.log(error);
        if (error.isAxiosError) {
            if ('response' in error) {
                enqueueSnackbar(error.response.data.message, { variant: 'error' });
                return;
            } else if (error.message === 'Network Error') {
                enqueueSnackbar('Ошибка сети', { variant: 'error' });
                return;
            }
        }
        enqueueSnackbar(error.message, { variant: 'error' });
    };
};

export default useErrorHandler;
