import { useState } from 'react';
import { TextField } from '@mui/material';

/**
 * Компонент текстового поля с поддержкой внутреннего состояния.
 * Особенность в том что onChange отрабатывает только при потере фокуса полем
 * @param {object} props - Объект с пропсами.
 * @param {string} [props.value=''] - Начальное значение текстового поля.
 * @param {function} props.onChange - Обработчик изменения текстового поля.
 *
 * @returns {JSX.Element} - Компонент текстового поля с управлением состоянием.
 */
const TextFieldWithState = (props) => {
    const [value, setValue] = useState(props.value);

    return (
        <TextField
            {...props}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={props.onChange}
            onFocus={(e) => e.target.select()}
        />
    );
};

export default TextFieldWithState;
