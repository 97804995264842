export const createPalette = () => {
    return {
        primary: {
            light: '#8d919c',
            main: '#313338',
            dark: '#28272b',
        },
        secondary: {
            light: '#d13434',
            main: '#a30000',
            dark: '#6a0000',
        },
        error: {
            light: '#ef5350',
            main: '#d32f2f',
            dark: '#c62828',
        },
        warning: {
            light: '#ff9800',
            main: '#ed6c02',
            dark: '#e65100',
        },
        info: {
            light: '#03a9f4',
            main: '#0288d1',
            dark: '#01579b',
        },
        success: {
            light: '#4caf50',
            main: '#2e7d32',
            dark: '#1b5e20',
        },
    };
};
