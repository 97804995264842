/**
 * Вторая версия диалога выбора серийных номеров для кос
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeAutoObservable } from 'mobx';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import rangeToString from '../../services/range-string';
import KosaService from '../../services/kosa-service';
import useMedia from '../../services/media-query';
import useErrorHandler from '../../services/error-handler';
import { FocusableTextField, StyledDialogTitle } from '../StyledComponents';

// prettier-ignore
const STATES = {
    RESERVED        : null,
    READY           : 1,
    CALIBRATE       : 2,
    CASE            : 3,
    CASE_AND_VERIFY : 4,
    CREATED         : 5,
    CONNECTED       : 6,
    REPEAT          : 7,
    ORDERED         : 8,
    THIS_RESERVED   : 9,
}

const DEFAULT_CELL_STYLE = {
    border: '2px solid black',
    padding: '0 6px',
    color: 'black',
    fontSize: '1em',
};

//=================================================================================================
const COLUMNS_SIZE = 10;
const ROWS_SIZE = 10;
const SHOW_TOTAL_ITEMS = COLUMNS_SIZE * ROWS_SIZE;
const SHOW_OLD_ROWS_SIZE = 2; //сколько старых строк (строк после последнего индекса) показывать в диалоге. Здесь имеется в виду первичная загрузка диалога.

class Store {
    load = false;
    start = 0;
    items = [];

    constructor() {
        makeAutoObservable(this);
    }

    async _loadItems() {
        this.load = true;
        try {
            const answer = await KosaService.get(this.start, this.start + SHOW_TOTAL_ITEMS);
            this.items = answer.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки данных');
        } finally {
            this.load = false;
        }
    }

    async _startBy(start) {
        this.start = start;
        await this._loadItems();
    }

    __calcStart(start) {
        let s = Math.floor(start / 10) * 10;
        if (s > SHOW_OLD_ROWS_SIZE * COLUMNS_SIZE) s -= SHOW_OLD_ROWS_SIZE * COLUMNS_SIZE;
        else s = 0;
        return s;
    }

    async initializeLoad() {
        try {
            const nextIdAnswer = await KosaService.getNextId();
            let nextId = nextIdAnswer.data;
            nextId = this.__calcStart(nextId);
            await this._startBy(nextId);
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка первичной загрузки данных');
        }
    }

    async initializeLoadFrom(id) {
        try {
            const s = this.__calcStart(id);
            await this._startBy(s);
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка первичной загрузки данных');
        }
    }

    async next() {
        await this._startBy(this.start + SHOW_TOTAL_ITEMS);
    }

    async prev() {
        let st = this.start - SHOW_TOTAL_ITEMS;
        if (st < 0) st = 0;
        await this._startBy(st);
    }

    async startBy(start) {
        const by = start < 0 ? 0 : Math.floor(start / 10) * 10;
        await this._startBy(by);
    }

    item(id) {
        const i = this.items.find((item) => +item.kosa_sn === id);
        return i ? i : { kosa_sn: id };
    }
}

const store = new Store();

//=================================================================================================
/**
 * Отображение скелета таблицы при загрузке
 */
const LoadSkeleton = () => {
    const renderRow = () => {
        const items = [];
        for (let i = 0; i < COLUMNS_SIZE; i++) {
            items.push(
                <TableCell key={i} sx={DEFAULT_CELL_STYLE}>
                    <Skeleton variant="text" sx={{ fontSize: '1.845rem' }} />
                </TableCell>
            );
        }
        return items;
    };

    const renderItems = () => {
        const out = [];
        for (let i = store.start; i < store.start + SHOW_TOTAL_ITEMS; i += COLUMNS_SIZE)
            out.push(<TableRow key={i}>{renderRow(i)}</TableRow>);
        return out;
    };

    return renderItems();
};

//=================================================================================================
/**
 * Отображает отдельный элемент SNS с чекбоксом и меткой.
 *
 * @param {object} sns - Объект элемента SNS.
 * @param {number} sns.kosa_sn - Идентификатор элемента SNS.
 * @param {number} sns.state_id - Состояние элемента SNS.
 * @param {function} onChange - Функция обратного вызова для обработки изменения чекбокса.
 * @return {JSX.Element} Отображаемый компонент элемента SNS.
 */
const SnsItem = ({ sns, onChange }) => {
    const onCheckBoxChange = (event) => {
        const checked = event.target.checked;
        onChange(sns, checked);
    };
    const tableCellStyle = () => {
        const def = DEFAULT_CELL_STYLE;
        if ('state_id' in sns) {
            // prettier-ignore
            switch (sns.state_id) {
                case STATES.THIS_RESERVED : return { ...def, color: '#38751e' };
                case STATES.RESERVED      : return { ...def, color: '#A66300' };
                case STATES.ORDERED       : return { ...def, color: '#38751e', backgroundColor: '#b7d7a8' };
                default : return { ...def, backgroundColor: '#b7b7b7' };
            }
        }
        return def;
    };

    const checkboxStyle = () => {
        if ('state_id' in sns) {
            // prettier-ignore
            switch (sns.state_id) {
                case STATES.THIS_RESERVED : return { color: '#38751e', '&.MuiCheckbox-root': { color: '#38751e' } };
                case STATES.RESERVED      : return {
                    '&.Mui-disabled': { color: '#A66300' },
                    '&.MuiFormControlLabel-root.MuiFormControlLabel-label.Mui-disabled': { color: '#A66300' },
                };
                case STATES.ORDERED       : return {
                    '&.Mui-disabled': { color: '#38751e' },
                    '&.MuiFormControlLabel-root..MuiFormControlLabel-label.Mui-disabled': { color: '#38751e', },
                }
                default : return null
            }
        }
    };

    const disabled = 'state_id' in sns && sns.state_id < STATES.THIS_RESERVED; //редактировать можно только текущий пункт заказа

    return (
        <TableCell sx={tableCellStyle()}>
            <FormControlLabel
                sx={{ marginRight: 0 }}
                control={
                    <Checkbox
                        sx={checkboxStyle()}
                        id={sns.kosa_sn}
                        checked={'state_id' in sns}
                        disabled={disabled}
                        onChange={onCheckBoxChange}
                    />
                }
                label={sns.kosa_sn}
            />
        </TableCell>
    );
};

//=================================================================================================
//=================================================================================================

const SnDialog = ({ sns = [], quantity = 1, open, onApply, onClose }) => {
    const [checkedSns, _setCheckedSns] = useState([]);
    const [startSn, _setStartSn] = useState(0);

    const { isMobile } = useMedia();
    const pushError = useErrorHandler();

    useEffect(() => {
        if (open) {
            _setCheckedSns(sns);
            initialize();
        }
    }, [open]);

    const initialize = async () => {
        try {
            if (sns.length) {
                await store.initializeLoadFrom(sns.at(0));
                _setStartSn(store.start);
            } else {
                await store.initializeLoad();
                _setStartSn(store.start);
            }
        } catch (e) {
            pushError(e);
        }
    };

    const snsItemChanged = (sns, checked) => {
        /*HACK здесь проблема так как предусмотренна возможность снять 
        выбор с другого заказа не находящегося в обработке. очень плохая операция. 
        пока это будет запрещено.
        */
        const pushSn = (sn) => {
            const newSns = [...checkedSns, +sn];
            newSns.sort((a, b) => a - b);
            _setCheckedSns(newSns);
        };

        const removeSn = (sn) => {
            const i = checkedSns.indexOf(+sn);
            if (i !== -1) {
                const nsns = [...checkedSns];
                nsns.splice(i, 1);
                _setCheckedSns(nsns);
            }
        };

        if (checked) pushSn(sns.kosa_sn);
        else removeSn(sns.kosa_sn);
    };

    const onApplyClick = () => {
        onApply(checkedSns);
    };

    const nextPage = async () => {
        try {
            _setStartSn(startSn + SHOW_TOTAL_ITEMS);
            await store.next();
        } catch (e) {
            pushError(e);
        }
    };

    const prevPage = async () => {
        try {
            const sn = startSn - SHOW_TOTAL_ITEMS;
            _setStartSn(sn > 0 ? sn : 0);
            await store.prev();
        } catch (e) {
            pushError(e);
        }
    };

    const toSn = async (sn) => {
        try {
            _setStartSn(sn);
            await store.startBy(sn);
        } catch (e) {
            pushError(e);
        }
    };

    //---------------------------------------------------------------------------------------------
    const renderItem = (id) => {
        /*
        отображение может быть:
            1. коса зарезервирована или участвует в другом заказе.
            2. коса зарезервирована и участвует в этом заказе.
            3. коса уже на стадии производства, а мы вернули заказ в редактирование. 
        */
        let i = store.item(id);
        if (checkedSns.includes(id)) {
            if ('state_id' in i)
                i = {
                    ...i,
                    state_id:
                        i.state_id !== STATES.RESERVED ? STATES.ORDERED : STATES.THIS_RESERVED,
                };
            else i = { ...i, state_id: STATES.THIS_RESERVED };
        } else if (sns && sns.includes(id)) {
            //если коса оказалась не в списке заказа, хотя изначально она в нем была (сняли галочку) то она должна отображаться как обычная, чтобы ее вновь можно было выбрать
            i = { kosa_sn: i.kosa_sn };
        }
        return (
            <SnsItem
                key={i.kosa_sn}
                sns={i}
                onChange={(sns, checked) => snsItemChanged(sns, checked)}
            />
        );
    };

    const renderRow = (startSn) => {
        const out = [];
        for (let i = 0; i < COLUMNS_SIZE; i++) out.push(renderItem(startSn + i));
        return <TableRow key={startSn}>{out}</TableRow>;
    };

    const renderItems = () => {
        const out = [];
        for (let i = store.start; i < store.start + SHOW_TOTAL_ITEMS; i += COLUMNS_SIZE)
            out.push(renderRow(i));
        return out;
    };

    const renderStatus = () => {
        if (!checkedSns.length) return null;
        const bStyle = { fontWeight: 'bold' };
        const countStyle =
            checkedSns.length === quantity
                ? { ...bStyle, color: 'green' }
                : { ...bStyle, color: 'red' };
        return (
            <Typography noWrap>
                Выбрано <span style={countStyle}>{checkedSns.length}</span> из{' '}
                <span style={bStyle}>{quantity}</span>
            </Typography>
        );
    };

    //---------------------------------------------------------------------------------------------
    return (
        <Dialog open={open} onClose={onClose} maxWidth="false" fullScreen={isMobile}>
            <StyledDialogTitle>Серийные номера для кос</StyledDialogTitle>
            <DialogContent sx={{ padding: '0 24px' }}>
                <Table size="small" sx={{ padding: 0 }} fullWidth>
                    <TableBody>{store.load ? <LoadSkeleton /> : renderItems()}</TableBody>
                </Table>
            </DialogContent>
            <DialogContent sx={{ padding: '0 24px' }}>
                <Stack>
                    <Stack direction="row" margin={1}>
                        <Tooltip title="Предыдущие">
                            <IconButton
                                disabled={store.start === 0}
                                onClick={prevPage}
                                size="small">
                                <NavigateBeforeIcon />
                            </IconButton>
                        </Tooltip>
                        <FocusableTextField
                            value={startSn}
                            onChange={(e) => toSn(+e.target.value)}
                            size="small"
                            InputProps={{
                                inputProps: { min: 0, type: 'number', step: SHOW_TOTAL_ITEMS },
                            }}
                        />
                        <Tooltip title="Следующие">
                            <IconButton onClick={nextPage} size="small">
                                <NavigateNextIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Typography align="center">{renderStatus()}</Typography>
                    <Typography align="center">
                        {checkedSns.length ? rangeToString(checkedSns) : 'Ничего не выбрано'}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onApplyClick}>
                    Применить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default observer(SnDialog);
