import { useEffect, useState, useContext } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControlLabel,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import MenuIcon from '@mui/icons-material/Menu';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import { AuthContext } from '../../index';
import api from '../../http';
import ColorDialog from '../ColorPickerDialog';
import {
    StyledSortLabel,
    StyledTableCell,
    TextFieldWithState,
    StyledDialogTitle,
    FocusableTextField,
    StyledAutocomplete,
} from '../StyledComponents';
import useErrorHandler from '../../services/error-handler';
import useMedia from '../../services/media-query';

//=================================================================================================
class TagsStore {
    tags = [];

    constructor() {
        makeAutoObservable(this);
        this.load().catch((e) => console.warn(e));
    }

    async load() {
        try {
            const res = await api.get('/tags');
            this.tags = res.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка загрузки тегов');
        }
    }

    async save(tag) {
        try {
            await api.post('/tags', { tag });
            await this.load();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка сохранения тега');
        }
    }

    async remove(tag_id) {
        try {
            await api.delete(`/tags/${tag_id}`);
            await this.load();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка удаления тега');
        }
    }

    async checkName(tagName) {
        if (tagName.length === 0) throw new Error('Имя не может быть пустым');
        let answer;
        try {
            answer = await api.get('/tags/' + tagName);
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка сети');
        }
        if (answer.data === true) throw new Error('Имя уже существует');
    }
}

const tagsStore = new TagsStore();

//=================================================================================================
const EditNameTextField = (props) => {
    const [oldValue, setOldValue] = useState('');
    const [value, setValue] = useState('');
    const [error, setError] = useState(' ');

    useEffect(() => {
        setOldValue(props.value);
        setValue(props.value);
    }, []);

    const onChangeVal = (e) => {
        const nVal = e.target.value;
        setValue(nVal);
        if (nVal.length === 0) setError('Имя не может быть пустым');
        else if (nVal === oldValue) setError(' ');
        else
            tagsStore
                .checkName(nVal)
                .then(() => setError(' '))
                .catch((e) => setError(e.message));
    };

    return (
        <FocusableTextField
            {...props}
            size="small"
            margin="normal"
            label="Имя"
            value={value}
            error={error.length > 1}
            helperText={error}
            onChange={onChangeVal}
            onBlur={props.onChange}
        />
    );
};

//-------------------------------------------------------------------------------------------------
const generateColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
};

const CreateTagDialog = ({ open, onClose }) => {
    const [tagName, setTagName] = useState('');
    const [tagDescription, setTagDescription] = useState('');
    const [tagColor, setTagColor] = useState(generateColor());
    const [colorDialog, setColorDialog] = useState(false);

    const errorHandler = useErrorHandler();
    const { isMobile } = useMedia();

    useEffect(() => {
        if (open) {
            setTagName('');
            setTagDescription('');
            setTagColor(generateColor());
            setColorDialog(false);
        }
    }, [open]);

    const onCreateNewTagClick = async () => {
        try {
            await tagsStore.save({ name: tagName, description: tagDescription, color: tagColor });
            onClose();
        } catch (e) {
            errorHandler(e);
        }
    };

    return (
        <>
            <Dialog open={open} onClose={onClose}>
                <StyledDialogTitle>Создать новый тэг</StyledDialogTitle>
                <DialogContent>
                    <Stack
                        direction="row"
                        alignItems={isMobile ? 'center' : 'baseline'}
                        spacing={1}
                        sx={isMobile ? { paddingTop: 1 } : {}}>
                        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                            <EditNameTextField
                                value={tagName}
                                onChange={(e) => setTagName(e.target.value)}
                            />
                            <TextFieldWithState
                                size="small"
                                label="Описание"
                                margin="normal"
                                helperText=" "
                                value={tagDescription}
                                onChange={(e) => setTagDescription(e.target.value)}
                            />
                        </Stack>
                        <Tooltip title="Изменить свет">
                            <IconButton
                                onClick={() => setColorDialog(true)}
                                sx={isMobile ? { top: -5 } : { top: 5 }}>
                                <CircleIcon sx={{ color: tagColor }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCreateNewTagClick} variant="contained" color="primary">
                        Создать
                    </Button>
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
            <ColorDialog
                color={tagColor}
                open={colorDialog}
                onClose={() => setColorDialog(false)}
                onApply={(color) => {
                    setTagColor(color);
                    setColorDialog(false);
                }}
            />
        </>
    );
};

//-------------------------------------------------------------------------------------------------
const TagsDialog = observer(({ open, onClose }) => {
    const [labelForColor, setTagForColor] = useState(null); //в момент изменения цвета, здесь хранится лейбл для которого вызван диалог смены цвета
    const [showCreateTagDialog, setShowCreateTagDialog] = useState(false);

    const errorHandler = useErrorHandler();
    const { isMobile } = useMedia();

    const onTagChange = async (label) => {
        try {
            await tagsStore.save(label);
        } catch (e) {
            errorHandler(e);
        }
    };

    const renderItems = () => {
        const tags = tagsStore.tags;

        return tags.map((tag) => {
            return (
                <TableRow key={tag.tag_id}>
                    <TableCell>
                        <div style={{ height: 75 }}>
                            <EditNameTextField
                                value={tag.name}
                                onChange={(e) => onTagChange({ ...tag, name: e.target.value })}
                            />
                        </div>
                    </TableCell>
                    <TableCell>
                        <div style={{ height: 75 }}>
                            <TextFieldWithState
                                size="small"
                                margin="normal"
                                label="Описание"
                                helperText=" "
                                value={tag.description}
                                onChange={(e) =>
                                    onTagChange({ ...tag, description: e.target.value })
                                }
                            />
                        </div>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', width: '1%', padding: 0 }}>
                        <Tooltip title="Изменить свет">
                            <IconButton onClick={() => setTagForColor(tag)}>
                                <CircleIcon sx={{ color: tag.color }} />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', width: '1%', padding: 0 }}>
                        <Tooltip title="Удалить">
                            <IconButton
                                onClick={() => {
                                    tagsStore.remove(tag.tag_id).catch((e) => errorHandler(e));
                                }}>
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            );
        });
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} fullScreen={isMobile}>
                <StyledDialogTitle>Управление тэгами</StyledDialogTitle>
                <DialogContent>
                    <Stack alignItems="end">
                        <Table size="small">{renderItems()}</Table>
                        <Tooltip title="Создать новый тэг">
                            <IconButton
                                onClick={() => setShowCreateTagDialog(true)}
                                sx={{ height: '2em', width: '2em' }}>
                                <AddBoxIcon
                                    color="success"
                                    sx={{ height: '1.5em', width: '1.5em' }}
                                />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} variant="contained" color="primary">
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
            <ColorDialog
                open={Boolean(labelForColor)}
                color={labelForColor?.color}
                onClose={() => setTagForColor(null)}
                onApply={(c) => onTagChange({ ...labelForColor, color: c })}
            />
            <CreateTagDialog
                open={showCreateTagDialog}
                onClose={() => setShowCreateTagDialog(false)}
            />
        </>
    );
});

//=================================================================================================
const TagsMenu = observer(({ anchor, onClose, onLabelsClick }) => {
    const [showTagsDialog, setShowTagsDialog] = useState(false);

    const renderItem = () => {
        const tags = tagsStore.tags;
        return tags.map((tag) => (
            <MenuItem key={tag.tag_id} onClick={() => onLabelsClick(tag)}>
                {/* <Tooltip title={`Добавить тэг ${tag.name}`}> */}
                <Chip
                    label={tag.name}
                    sx={{ backgroundColor: tag.color, color: 'white', width: '100%' }}
                />
                {/* </Tooltip> */}
            </MenuItem>
        ));
    };

    return (
        <>
            <Menu anchorEl={anchor} open={Boolean(anchor)} onClose={onClose}>
                <MenuItem
                    disabled
                    sx={{
                        color: 'white',
                        backgroundColor: 'black',
                        top: -10,
                        fontSize: '1.2em',
                        margin: 0,
                    }}>
                    Добавить тэг
                </MenuItem>
                <MenuList>{renderItem()}</MenuList>
                <Divider />
                <MenuItem onClick={() => setShowTagsDialog(true)}>
                    <ListItemIcon>
                        <MenuIcon />
                    </ListItemIcon>
                    Редактировать тэги
                </MenuItem>
            </Menu>
            <TagsDialog open={showTagsDialog} onClose={() => setShowTagsDialog(false)} />
        </>
    );
});

//=================================================================================================
class PurchaseNamesStore {
    names = [];

    constructor() {
        this.load();
    }

    async load() {
        try {
            const res = await api.get('/purchase');
            this.names = res.data;
        } catch (e) {
            console.warn(e);
        }
    }
}

const purchaseNamesStore = new PurchaseNamesStore();

const purchaseNames = () => {
    const names = purchaseNamesStore.names;
    return names.map((name) => name.name);
};

//-------------------------------------------------------------------------------------------------
class PurchaseStore {
    totalItemSize = 0;
    pageSize = 20;
    page = 0;
    purchases = [];
    filter = { active: true, name: '', tags: [] };
    sort = {}; //{ column: 'column_name', order: 'asc' || 'desc' }

    constructor() {
        makeAutoObservable(this);
    }

    async loadTotalItemSize() {
        try {
            const res = await api.post('/purchase/size', { filter: this.filter });
            this.totalItemSize = +res.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка получения размера данных с сервера');
        }
    }

    async loadItems() {
        try {
            const start = this.page * this.pageSize;
            const res = await api.post('/purchase', {
                start: start,
                pageSize: this.pageSize,
                filter: this.filter,
                sort: this.sort,
            });
            this.purchases = res.data;
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка получения записей с сервера');
        }
    }

    async save(purchase) {
        try {
            await api.post('/purchase/save', { purchase });
            await this.loadItems();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка сохранения записи');
        }
    }

    async setFilter(filter = { active: false, name: '', tags: [] }) {
        this.filter = filter;
        this.page = 0;
        await this.loadTotalItemSize();
        await this.loadItems();
    }

    async setSort(sort = {}) {
        this.sort = sort;
        await this.loadItems();
    }

    async setPage(page) {
        this.page = page;
        await this.loadItems();
    }

    async setPageSize(pageSize) {
        this.pageSize = pageSize;
        await this.loadItems();
    }

    async remove(purchase_id) {
        try {
            await api.delete(`/purchase/${purchase_id}`);
            await this.loadItems();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка удаления записи');
        }
    }

    async finish(purchase) {
        try {
            await api.post(`/purchase/finish`, { purchase });
            await this.loadItems();
        } catch (e) {
            console.warn(e);
            throw new Error('Ошибка сохранения записи');
        }
    }
}

const purchaseStore = new PurchaseStore();

//=================================================================================================
const AddPurchaseFrame = observer(() => {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [tags, setTags] = useState([]);
    const [tagsMenuAnchor, setTagsMenuAnchor] = useState(null);

    const errorHandler = useErrorHandler();
    const { isMobile } = useMedia();

    const onNameKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            const nextInputElement = document.getElementById('quantity-textfield');
            nextInputElement.focus();
            setName(e.target.value);
        }
    };

    const onQuantityKeyDown = (e) => {
        if (e.key === 'Enter') onAddButtonClick();
    };

    const onAddButtonClick = async () => {
        if (name.length === 0) {
            errorHandler(new Error('Название не может быть пустым'));
            return;
        }
        if (+quantity < 1) {
            errorHandler(new Error('Количество не может быть меньше 1'));
            return;
        }
        purchaseStore
            .save({ name: name, quantity: quantity, tags: tags })
            .then(() => {
                setName('');
                setQuantity(1);
                setTags([]);
            })
            .catch((e) => {
                errorHandler(e);
            });
    };

    const renderTags = () => {
        return tags.map((tag) => (
            <Tooltip key={tag.name} title={tag.description}>
                <Chip
                    label={tag.name}
                    size="small"
                    sx={{ backgroundColor: tag.color, color: 'white' }}
                    onDelete={() => {
                        const n = [...tags];
                        n.splice(n.indexOf(tag), 1);
                        setTags(n);
                    }}
                />
            </Tooltip>
        ));
    };

    return (
        <Paper sx={{ padding: '1em' }}>
            <Stack direction="row" spacing={2}>
                <Stack spacing={1} sx={{ width: '100%' }}>
                    <Stack direction={isMobile ? 'column' : 'row'} spacing={2} alignItems="start">
                        <div style={{ width: '100%' }}>
                            <StyledAutocomplete
                                freeSolo
                                fullWidth
                                inputValue={name}
                                onInputChange={(_, v) => setName(v)}
                                options={purchaseNames()}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        onKeyDown={onNameKeyDown}
                                        label="Название"
                                        margin="normal"
                                        size="small"
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <FocusableTextField
                                id="quantity-textfield"
                                label="Количество"
                                size="small"
                                margin="normal"
                                sx={{ minWidth: '8em' }}
                                InputProps={{
                                    inputProps: { min: 1, max: 10000, type: 'number' },
                                }}
                                value={quantity}
                                onChange={(e) => setQuantity(e.target.value)}
                                onKeyDown={onQuantityKeyDown}
                            />
                        </div>
                    </Stack>
                    <Stack direction="row" spacing={2} useFlexGap flexWrap={'wrap'}>
                        {renderTags()}
                        <Tooltip title="Добавить тэг">
                            <Chip
                                label="+"
                                size="small"
                                onClick={(e) => setTagsMenuAnchor(e.currentTarget)}
                                color="success"
                            />
                        </Tooltip>
                    </Stack>
                </Stack>
                <Tooltip title="Добавить">
                    <IconButton color="primary" onClick={onAddButtonClick}>
                        <AddBoxIcon sx={{ height: '2em', width: '2em' }} />
                    </IconButton>
                </Tooltip>
            </Stack>
            <TagsMenu
                anchor={tagsMenuAnchor}
                onClose={() => setTagsMenuAnchor(null)}
                onLabelsClick={(tag) => {
                    const nt = new Set([...tags, tag]);
                    setTags([...nt]);
                }}
            />
        </Paper>
    );
});

//=================================================================================================
const FiltersFrame = observer(() => {
    const [tagsMenuAnchor, setTagsMenuAnchor] = useState(null);

    const errorHandler = useErrorHandler();

    const setFilters = (filters) => {
        purchaseStore.setFilter(filters).catch((e) => {
            errorHandler(e);
        });
    };

    const onNameKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') e.preventDefault();
    };

    const renderTags = () => {
        return purchaseStore.filter.tags.map((tag) => (
            <Tooltip key={tag.name} title={tag.description}>
                <Chip
                    label={tag.name}
                    size="small"
                    sx={{ backgroundColor: tag.color, color: 'white' }}
                    onDelete={() => {
                        const t = [...purchaseStore.filter.tags];
                        t.splice(t.indexOf(tag), 1);
                        const f = { ...purchaseStore.filter, tags: t };
                        setFilters(f);
                    }}
                />
            </Tooltip>
        ));
    };

    return (
        <>
            <Accordion>
                <AccordionSummary
                    sx={{
                        flexDirection: 'row-reverse',
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                            transform: 'rotate(90deg)',
                        },
                    }}
                    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
                    <Typography variant="h6" sx={{ paddingLeft: '0.5em' }}>
                        Фильтры
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack>
                        <FormControlLabel
                            label="Активные"
                            control={
                                <Checkbox
                                    size="small"
                                    checked={purchaseStore.filter.active}
                                    onChange={(e) => {
                                        const nf = {
                                            ...purchaseStore.filter,
                                            active: e.target.checked,
                                        };
                                        setFilters(nf);
                                    }}
                                />
                            }
                        />
                        <StyledAutocomplete
                            freeSolo
                            includeInputInList
                            inputValue={purchaseStore.filter.name}
                            onInputChange={(_, v) => {
                                const nf = { ...purchaseStore.filter, name: v };
                                setFilters(nf);
                            }}
                            options={purchaseNames()}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Название"
                                    margin="normal"
                                    size="small"
                                    onKeyDown={onNameKeyDown}
                                />
                            )}
                        />
                        <Paper sx={{ padding: '0.5em', marginTop: '0.5em' }} elevation={2}>
                            <Stack direction="row" spacing={2} useFlexGap flexWrap={'wrap'}>
                                {renderTags()}
                                <Tooltip title="Добавить тэг">
                                    <Chip
                                        label="+"
                                        size="small"
                                        onClick={(e) => setTagsMenuAnchor(e.currentTarget)}
                                        color="success"
                                    />
                                </Tooltip>
                            </Stack>
                        </Paper>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <TagsMenu
                anchor={tagsMenuAnchor}
                onClose={() => setTagsMenuAnchor(null)}
                onLabelsClick={(tag) => {
                    const tags = new Set([...purchaseStore.filter.tags, tag]);
                    const nf = { ...purchaseStore.filter, tags: [...tags] };
                    setFilters(nf);
                }}
            />
        </>
    );
});

//=================================================================================================
const PurchaseEditDialog = ({ purchase, onClose }) => {
    const [name, setName] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [tags, setTags] = useState([]);
    const [tagsMenuAnchor, setTagsMenuAnchor] = useState(null);

    const errorHandler = useErrorHandler();
    const { isMobile } = useMedia();

    useEffect(() => {
        if (purchase) {
            setName(purchase.name);
            setQuantity(purchase.quantity);
            setTags(purchase.tags);
            setTagsMenuAnchor(null);
        }
    }, [purchase]);

    const onApplyClick = () => {
        if (name.length === 0) {
            errorHandler(new Error('Название не может быть пустым'));
            return;
        }
        if (+quantity < 1) {
            errorHandler(new Error('Количество не может быть меньше 1'));
            return;
        }
        const p = { ...purchase, name: name, quantity: quantity, tags: tags };
        purchaseStore
            .save(p)
            .then(() => onClose())
            .catch((e) => {
                errorHandler(e);
            });
    };

    const onNameKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            const nextInputElement = document.getElementById('dialog-quantity-textfield');
            nextInputElement.focus();
            setName(e.target.value);
        }
    };

    const onQuantityKeyDown = (e) => {
        if (e.key === 'Enter') onApplyClick();
    };

    const renderTags = () => {
        return tags.map((tag) => (
            <Tooltip key={tag.name} title={tag.description}>
                <Chip
                    label={tag.name}
                    size="small"
                    sx={{ backgroundColor: tag.color, color: 'white' }}
                    onDelete={() => {
                        const n = [...tags];
                        n.splice(n.indexOf(tag), 1);
                        setTags(n);
                    }}
                />
            </Tooltip>
        ));
    };

    if (!purchase) return null;

    return (
        <>
            <Dialog open={purchase} onClose={onClose} fullWidth>
                <StyledDialogTitle>Редактирование заявки</StyledDialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            spacing={2}
                            alignItems="center">
                            <div style={{ width: '100%' }}>
                                <StyledAutocomplete
                                    freeSolo
                                    includeInputInList
                                    fullWidth
                                    inputValue={name}
                                    onInputChange={(_, v) => setName(v)}
                                    options={purchaseNames()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Название"
                                            margin="normal"
                                            size="small"
                                            onKeyDown={onNameKeyDown}
                                        />
                                    )}
                                />
                            </div>
                            <div style={isMobile ? { width: '100%' } : {}}>
                                <FocusableTextField
                                    id="dialog-quantity-textfield"
                                    label="Количество"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    sx={{ minWidth: '8em' }}
                                    InputProps={{
                                        inputProps: { min: 1, max: 10000, type: 'number' },
                                    }}
                                    value={quantity}
                                    onChange={(e) => setQuantity(e.target.value)}
                                    onKeyDown={onQuantityKeyDown}
                                />
                            </div>
                        </Stack>
                        <Stack direction="row" spacing={2} useFlexGap flexWrap={'wrap'}>
                            {renderTags()}
                            <Tooltip title="Добавить тэг">
                                <Chip
                                    label="+"
                                    size="small"
                                    onClick={(e) => setTagsMenuAnchor(e.currentTarget)}
                                    color="success"
                                />
                            </Tooltip>
                        </Stack>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onApplyClick}>
                        Сохранить
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
            <TagsMenu
                anchor={tagsMenuAnchor}
                onClose={() => setTagsMenuAnchor(null)}
                onLabelsClick={(tag) => {
                    const f = tags.find((t) => t.name === tag.name);
                    if (f) return;
                    setTags([...tags, tag]);
                }}
            />
        </>
    );
};

//=================================================================================================
const PurchaseDeleteDialog = ({ purchase, onClose }) => {
    const errorHandler = useErrorHandler();

    const onDeleteClick = () => {
        purchaseStore
            .remove(purchase.purchase_id)
            .then(() => {
                onClose();
            })
            .catch((e) => {
                errorHandler(e);
            });
    };

    if (!purchase) return null;

    return (
        <>
            <Dialog open={purchase} onClose={onClose}>
                <StyledDialogTitle>Удаление заявки</StyledDialogTitle>
                <DialogContent>Вы действительно хотите удалить заявку?</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onDeleteClick}>
                        Удалить
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

//=================================================================================================
const PurchaseFinishDialog = ({ purchase, onClose }) => {
    const errorHandler = useErrorHandler();

    const onFinishClick = () => {
        purchaseStore
            .finish(purchase)
            .then(() => {
                onClose();
            })
            .catch((e) => {
                errorHandler(e);
            });
    };

    if (!purchase) return null;
    return (
        <>
            <Dialog open={purchase} onClose={onClose}>
                <StyledDialogTitle>Закрытие заявки</StyledDialogTitle>
                <DialogContent>
                    <Typography variant="h6">Вы действительно хотите закрыть заявку?</Typography>
                    После закрытия заявки нельзя будет ее отредактировать или удалить.
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={onFinishClick}>
                        Закрыть
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

//=================================================================================================
const PurchaseTable = observer(({ onEdit, onRemove, onFinish }) => {
    const { authStore } = useContext(AuthContext);

    const errorHandler = useErrorHandler();

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        try {
            await purchaseStore.loadTotalItemSize();
            await purchaseStore.loadItems();
        } catch (e) {
            errorHandler(e);
        }
    };

    const renderHeader = () => {
        const sortHandler = async (columnId) => {
            try {
                const isAsc =
                    purchaseStore.sort.column === columnId && purchaseStore.sort.order === 'asc';
                const sort = {
                    column: columnId,
                    order: isAsc ? 'desc' : 'asc',
                };
                await purchaseStore.setSort(sort);
            } catch (e) {
                errorHandler(e);
            }
        };

        //prettier-ignore
        const items = [
            { id: 'name',        label: 'Наименование' },
            { id: 'quantity',    label: 'Количество' },
            { id: 'start_time',  label: 'Создано' },
            { id: 'finish_time', label: 'Закрыто' },
        ];

        const h = items.map((i) => (
            <StyledTableCell
                key={i.id}
                sortDirection={
                    purchaseStore.sort.column === i.id ? purchaseStore.sort.order : false
                }>
                <StyledSortLabel
                    active={purchaseStore.sort.column === i.id}
                    direction={purchaseStore.sort.order}
                    onClick={() => sortHandler(i.id)}>
                    {i.label}
                </StyledSortLabel>
            </StyledTableCell>
        ));
        h.push(<StyledTableCell />);

        return h;
    };

    const adminOrCreator = (purchase) => {
        if (purchase.finish_time !== null) return false;
        if (authStore.user.role === 2) return true;
        return purchase.start_user === authStore.user.id;
    };

    const renderRow = () => {
        const items = purchaseStore.purchases;
        return items.map((item) => (
            <TableRow key={item.purchase_id} hover>
                <TableCell>
                    <Stack>
                        <Typography variant="body1">{item.name}</Typography>
                        <Stack direction="row" spacing={2} useFlexGap flexWrap={'wrap'}>
                            {item.tags.map((tag) => (
                                <Tooltip key={tag.name} title={tag.description}>
                                    <Chip
                                        label={tag.name}
                                        size="small"
                                        sx={{ backgroundColor: tag.color, color: 'white' }}
                                    />
                                </Tooltip>
                            ))}
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{DateTime.fromISO(item.start_time).toFormat('dd.MM.yyyy')}</TableCell>
                <TableCell>
                    {item.finish_time
                        ? DateTime.fromISO(item.finish_time).toFormat('dd.MM.yyyy')
                        : ''}
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap', width: '1%' }}>
                    {/* Действия */}
                    <Stack direction="row" spacing={2}>
                        <Tooltip title="Редактировать">
                            <IconButton
                                color="info"
                                onClick={() => onEdit(item)}
                                disabled={!adminOrCreator(item)}>
                                <ModeEditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Удалить">
                            <IconButton
                                color="error"
                                onClick={() => onRemove(item)}
                                disabled={!adminOrCreator(item)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Закрыть заявку">
                            <IconButton
                                color="success"
                                onClick={() => onFinish(item)}
                                disabled={item.finish_time !== null || authStore.user.role !== 2}>
                                <DoneIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>{renderHeader()}</TableRow>
                </TableHead>
                <TableBody>{renderRow()}</TableBody>
                <TableFooter>
                    <TableRow>
                        <TableCell colSpan={5}>
                            <TablePagination
                                rowsPerPageOptions={[10, 20, 50]}
                                component="div"
                                count={purchaseStore.totalItemSize}
                                rowsPerPage={purchaseStore.pageSize}
                                page={purchaseStore.page}
                                onPageChange={(_, page) => {
                                    purchaseStore.setPage(page).catch((e) => {
                                        errorHandler(e);
                                    });
                                }}
                                onRowsPerPageChange={(e) => {
                                    purchaseStore.setPageSize(e.target.value).catch((err) => {
                                        errorHandler(err);
                                    });
                                }}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
});

//=================================================================================================
const Purchase = () => {
    const [forEdit, setForEdit] = useState(null);
    const [forRemove, setForRemove] = useState(null);
    const [forFinish, setForFinish] = useState(null);

    return (
        <>
            <Stack spacing={2} padding={1}>
                <AddPurchaseFrame />
                <FiltersFrame />
            </Stack>

            <PurchaseTable
                onEdit={(purchase) => setForEdit(purchase)}
                onRemove={(purchase) => setForRemove(purchase)}
                onFinish={(purchase) => setForFinish(purchase)}
            />

            <PurchaseEditDialog purchase={forEdit} onClose={() => setForEdit(null)} />
            <PurchaseDeleteDialog purchase={forRemove} onClose={() => setForRemove(null)} />
            <PurchaseFinishDialog purchase={forFinish} onClose={() => setForFinish(null)} />
        </>
    );
};

export default Purchase;
