import { useContext, useState } from 'react';
import { Button, Link } from '@mui/material';

import styles from './Login.module.css';
import { AuthContext } from '../../index';
import { ReactComponent as Logo } from '../../img/logo.svg';
import useErrorHandler from '../../services/error-handler';
import { FocusableTextField } from '../StyledComponents';

/**
 * @param onRegisterClick - обработка нажатия на ссылку "Регистрация"
 */
const Login = ({ onRegisterClick }) => {
    const { authStore } = useContext(AuthContext);
    const errorHandler = useErrorHandler();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const [loginError, setLoginError] = useState(' ');
    const [passwordError, setPasswordError] = useState(' ');

    const onButtonClick = async () => {
        const lError = login.length < 1 ? 'Поле "Логин" не может быть пустым' : ' ';
        const passLength = password.length;
        let pError = ' ';
        if (passLength < 1) pError = 'Поле "Пароль" не может быть пустым';
        else if (passLength < 4) pError = 'Пароль должен быть больше 3 символов';
        setLoginError(lError);
        setPasswordError(pError);
        if (lError.length < 2 && pError.length < 2) {
            try {
                await authStore.login(login, password);
            } catch (e) {
                errorHandler(e);
            }
        }
    };

    const onPasswordKeyDown = (event) => {
        if (event.key === 'Enter') onButtonClick();
    };

    return (
        <div className={styles.form}>
            <div className={styles.inputArea}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.fields}>
                    <FocusableTextField
                        label="Логин"
                        value={login}
                        onChange={(e) => setLogin(e.target.value)}
                        error={loginError.length > 2}
                        helperText={loginError}
                        variant="outlined"
                    />
                    <FocusableTextField
                        label="Пароль"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={onPasswordKeyDown}
                        error={passwordError.length > 2}
                        helperText={passwordError}
                        type="password"
                        variant="outlined"
                    />
                </div>
            </div>
            <div className={styles.buttons}>
                <Button onClick={onButtonClick} variant="contained">
                    Вход
                </Button>
            </div>
            <div>
                <Link onClick={onRegisterClick}>Регистрация</Link>
            </div>
        </div>
    );
};

export default Login;
