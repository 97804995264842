import { useState } from 'react';
import { Backdrop } from '@mui/material';
// import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Login from './Login';
import Registration from './Registration';

const User = () => {
    const [register, setRegister] = useState(false);

    const component = () => {
        if (register) {
            return (
                <Registration
                    onClick={() => {
                        setRegister(false);
                    }}
                />
            );
        } else {
            return (
                <Login
                    onRegisterClick={() => {
                        setRegister(true);
                    }}
                />
            );
        }
    };

    return (
        <Backdrop sx={{ backgroundColor: '#28272b' }} open={true}>
            {component()}
        </Backdrop>
    );
};

export default User;
