/**
 * Отображение списка заказов для пользователей с ролью "Администратор"
 */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Paper,
    MenuItem,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    TableRow,
    Checkbox,
    TableCell,
    Select,
    IconButton,
    Tooltip,
    Link,
    TableFooter,
    TablePagination,
    Skeleton,
    Stack,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { AdditionInfoContext } from '../ContentRoot/ContentRoot';
import { BuyerListContext } from '.';
import styles from './Orders.module.css';
import CreateOrderDialog from './CreateOrderDialog';
import { OrderStore } from '../../store/orderStore';
import { StyledTableCell, StyledDataPicker } from '../StyledComponents';
import SetOrdersState from './SetOrdersState';
import SetOrderState from './SetOrderState';
import useErrorHandler from '../../services/error-handler';
import RemoveDialog from './RemoveDialog';

const orderStore = new OrderStore();

const READY_STATE_ID = 2;

const isEditable = (order) => {
    return order.state_id < READY_STATE_ID;
};

//=================================================================================================
/**
 * отображает вернее меню с кнопками
 * @param {Boolean} selected - признак, что в таблице есть выбранные записи
 * @param {Function} onSetState(state_id) - вызывается если пользователь выбрал новое состояния для
 * выбранных заказов
 * @param {Function} onDelete() - вызывается если пользователь подтвердил удаление выбранных записей
 * @returns верхнее меню с кнопками
 */
const ActionButtons = observer(({ selected, onDelete }) => {
    const { buyerListStore } = useContext(BuyerListContext);
    const errorHandler = useErrorHandler();

    const navigate = useNavigate();

    const [showCreateDialog, setShowCreateDialog] = useState(false);

    const onCreateNewOrder = async (orderNum, buyerId) => {
        try {
            const response = await orderStore.create(orderNum, buyerId);
            navigate('/orders/' + response);
        } catch (e) {
            errorHandler(e);
        }
    };

    return (
        <>
            <div className={styles.buttonsArea}>
                <Button
                    color="success"
                    onClick={() => setShowCreateDialog(true)}
                    variant="contained">
                    Новый
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!selected}
                    onClick={onDelete}>
                    Удалить
                </Button>
            </div>
            <CreateOrderDialog
                open={showCreateDialog}
                buyersList={buyerListStore.buyers}
                onApply={onCreateNewOrder}
                onClose={() => setShowCreateDialog(false)}
            />
        </>
    );
});

//=================================================================================================
/**
 *
 * @param {{order_id, number, buyer_id, create_time, deadline_time,
 *      state_id, deleted, priority_id, comment}} order - данные о заказе
 * @param {Boolean} checked - Состояние checkbox строки
 * @param {Function} onCheckedChange(state, order) - функция изменения состояния checkbox
 * @param {Function} onSetState(state, order) - функция изменения состояния заказа
 * @param {Function} onDelete(order) - callback на нажатие кнопки удалить
 * @returns строку для использования в таблице заказов
 */
const OrderItem = observer(({ order, checked, onCheckedChange, onSetState, onDelete }) => {
    const { additionInformationStore } = useContext(AdditionInfoContext);
    const { buyerListStore } = useContext(BuyerListContext);

    const navigate = useNavigate();

    const onStateChange = (event) => {
        onSetState(event.target.value, order);
    };
    const onPriorityChange = (event) => {
        const newOrder = { ...order, priority_id: event.target.value };
        orderStore.update(newOrder);
    };
    const onDeadLineChange = (newValue) => {
        const newOrder = { ...order, deadline_time: newValue };
        orderStore.update(newOrder);
    };
    const onEditClick = () => {
        navigate('/orders/' + order.order_id);
    };

    //! Рендеринг меню статусов
    const renderStateMenu = () => {
        const states = additionInformationStore.statuses;
        const items = states.map((status) => {
            return (
                <MenuItem
                    value={status.state_id}
                    key={status.state_id}
                    disabled={order.state_id + 1 < status.state_id}>
                    {status.state}
                </MenuItem>
            );
        });
        return items;
    };
    //! Рендеринг меню приоритетов
    const renderPriorityMenu = () => {
        const priorities = additionInformationStore.priorities;
        const items = priorities.map((priority) => {
            return (
                <MenuItem value={priority.priority_id} key={priority.priority_id}>
                    {priority.state}
                </MenuItem>
            );
        });
        return items;
    };

    return (
        <TableRow hover sx={additionInformationStore.statusStyle(order.state_id)}>
            <TableCell padding="checkbox">
                <Checkbox
                    checked={checked}
                    onChange={(e) => {
                        onCheckedChange(e.target.checked, order);
                    }}
                />
            </TableCell>
            <TableCell>
                <Link href={`/orders/${order.order_id}`}>{order.number}</Link>
            </TableCell>
            <TableCell>
                <Link href={`/buyers/${order.buyer_id}`}>
                    {buyerListStore.buyerName(order.buyer_id)}
                </Link>
            </TableCell>
            <TableCell>
                <Select
                    variant="outlined"
                    size="small"
                    value={order.state_id}
                    onChange={onStateChange}
                    disabled={order.deleted}>
                    {renderStateMenu()}
                </Select>
            </TableCell>
            <TableCell>
                <div className={styles.datePicker}>
                    <StyledDataPicker
                        value={DateTime.fromISO(order.deadline_time)}
                        onChange={onDeadLineChange}
                        disabled={!isEditable(order)}
                    />
                </div>
            </TableCell>
            <TableCell>
                <Select
                    variant="outlined"
                    size="small"
                    value={order.priority_id}
                    onChange={onPriorityChange}
                    disabled={!isEditable(order)}>
                    {renderPriorityMenu()}
                </Select>
            </TableCell>
            <TableCell align="right">
                <div className={styles.actionZone}>
                    <Tooltip title="Редактировать">
                        <IconButton onClick={onEditClick}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Удалить">
                        <span>
                            <IconButton
                                color="secondary"
                                disabled={order.deleted}
                                onClick={() => onDelete(order)}>
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </TableCell>
        </TableRow>
    );
});

//=================================================================================================
const OrdersAdmSkeleton = () => {
    const onPageChange = (_, page) => {
        orderStore.setPage(page);
    };
    const onRowsPerPageChange = (event) => {
        orderStore.setPageSize(event.target.value);
    };
    const renderTableRows = () => {
        const rows = [];
        for (let i = 0; i < orderStore.pageSize; i++) {
            rows.push(
                <TableRow key={i}>
                    <TableCell padding="checkbox">
                        <Skeleton variant="rounded" width={20} height={20} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="rounded" width={120} height={30} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="rounded" width={150} height={30} />
                    </TableCell>
                    <TableCell>
                        <Skeleton variant="rounded" width={150} height={30} />
                    </TableCell>
                    <TableCell>
                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                            <Skeleton variant="circular" width={30} height={30} />
                            <Skeleton variant="circular" width={30} height={30} />
                        </Stack>
                    </TableCell>
                </TableRow>
            );
        }
        return rows;
    };

    return (
        <>
            <h2 className={styles.title}>Заказы</h2>
            <div className={styles.buttonsArea}>
                <Skeleton variant="rounded" width={320} height={40} />
                <Skeleton variant="rounded" width={170} height={40} />
            </div>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell padding="checkbox">
                                <Skeleton variant="rounded" width={20} height={20} />
                            </StyledTableCell>
                            <StyledTableCell>№ Заказа</StyledTableCell>
                            <StyledTableCell>Заказчик</StyledTableCell>
                            <StyledTableCell>Статус</StyledTableCell>
                            <StyledTableCell>Срок</StyledTableCell>
                            <StyledTableCell>Приоритет</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableRows()}</TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    component="div"
                                    count={orderStore.allOrdersSize}
                                    rowsPerPage={orderStore.pageSize}
                                    page={orderStore.page}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

//=================================================================================================
const OrdersAdm = () => {
    const errorHandler = useErrorHandler();

    const [selected, setSelected] = useState([]); //выбранные строки
    const [removeItems, setRemoveItems] = useState([]); //выбранные строк для удаления
    const [statusParams, setStatusParams] = useState(null); //параметры для процесса смены статусов заказов и удаления заказа
    const [oneStatusParam, setOneStatusParam] = useState(null); //параметр для процесса смены статуса заказа (new)

    useEffect(() => {
        try {
            orderStore.loadSize();
            orderStore.loadOrders();
        } catch (e) {
            errorHandler(e);
        }
    }, []);

    const onPageChange = (_, page) => {
        orderStore.setPage(page);
    };
    const onRowsPerPageChange = (event) => {
        orderStore.setPageSize(event.target.value);
    };
    const onAllRowsCheckedChange = (event) => {
        if (event.target.checked) {
            setSelected(orderStore.orders.map((order) => order.order_id));
        } else {
            setSelected([]);
        }
    };
    const onRowCheckedChange = (checked, order) => {
        if (checked) {
            setSelected([...selected, order.order_id]);
        } else {
            const ns = selected.filter((item) => item !== order.order_id);
            setSelected(ns);
        }
    };
    const onSetStateOne = (state, order) => {
        // setStatusParams({ state_id: state, orders: [order] });
        setOneStatusParam({ state_id: state, order: order });
    };
    const stateChanged = async () => {
        try {
            setStatusParams(null);
            setOneStatusParam(null);
            await orderStore.loadOrders();
        } catch (e) {
            errorHandler(e);
        }
    };
    const onDelete = () => {
        const sel = orderStore.orders.filter(
            (order) => selected.includes(order.order_id) && !order.deleted
        );
        if (sel.length) setRemoveItems(sel);
    };
    const onDeleteOne = async (order) => {
        setRemoveItems([order]);
    };

    const onDeleteApply = async () => {
        setStatusParams({ state_id: 0, orders: removeItems });
        setRemoveItems([]);
    };

    if (orderStore.loading) return <OrdersAdmSkeleton />;

    //---------------------------------------------------------------------------------------------
    const renderTableRows = () => {
        const items = orderStore.orders.map((item) => (
            <OrderItem
                order={item}
                key={item.order_id}
                checked={selected.includes(item.order_id)}
                onCheckedChange={onRowCheckedChange}
                onSetState={onSetStateOne}
                onDelete={onDeleteOne}
            />
        ));
        return items;
    };

    return (
        <>
            <h2 className={styles.title}>Заказы</h2>
            <ActionButtons selected={Boolean(selected.length)} onDelete={onDelete} />
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell padding="checkbox">
                                <Checkbox
                                    checked={selected.length === orderStore.orders.length}
                                    indeterminate={
                                        selected.length !== 0 &&
                                        selected.length !== orderStore.orders.length
                                    }
                                    onChange={onAllRowsCheckedChange}
                                    sx={{
                                        color: grey[50],
                                        '&.Mui-checked': { color: grey[200] },
                                        '&.MuiCheckbox-indeterminate': { color: grey[200] },
                                    }}
                                />
                            </StyledTableCell>
                            <StyledTableCell>№ Заказа</StyledTableCell>
                            <StyledTableCell>Заказчик</StyledTableCell>
                            <StyledTableCell>Статус</StyledTableCell>
                            <StyledTableCell>Срок</StyledTableCell>
                            <StyledTableCell>Приоритет</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>{renderTableRows()}</TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={7}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    component="div"
                                    count={orderStore.allOrdersSize}
                                    rowsPerPage={orderStore.pageSize}
                                    page={orderStore.page}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <RemoveDialog
                open={removeItems.length > 0}
                title="Удалить?"
                text={
                    'Вы действительно хотите удалить заказ' +
                    (removeItems.length > 1 ? 'ы ' : ' ') +
                    removeItems.map((item) => item.number).join(', ')
                }
                onApply={onDeleteApply}
                onClose={() => setRemoveItems([])}
            />
            <SetOrdersState param={statusParams} onFinished={stateChanged} />
            <SetOrderState params={oneStatusParam} onFinished={stateChanged} />
        </>
    );
};

export default observer(OrdersAdm);
