import useErrorHandler from '../../services/error-handler';
import KosaService from '../../services/kosa-service';
import SnDialog from './SnDialog';

const SnsDialog = ({ orderItem, onClose }) => {
    const pushError = useErrorHandler();

    let sns = [];
    if (orderItem && orderItem.sns) {
        sns = orderItem.sns;
    }

    const quantity = orderItem ? orderItem.quantity : 1;

    const onApplyClick = async (sn) => {
        try {
            await KosaService.save({ ...orderItem, sns: sn });
            onClose();
        } catch (e) {
            pushError(e);
        }
    };

    return (
        <SnDialog
            open={orderItem}
            sns={sns}
            quantity={quantity}
            onApply={onApplyClick}
            onClose={onClose}
        />
    );
};

export default SnsDialog;
