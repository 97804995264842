import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import api from '../../http';
import useErrorHandler from '../../services/error-handler';
import OrderService from '../../services/orderService';
import KosaService from '../../services/kosa-service';
import { StyledDialogTitle } from '../StyledComponents';

const KOSA_DEVICE_TYPE = 0;
const STATES = {
    DELETE: 0,
    PREPARED: 1,
    PROCESSING: 2,
    READY: 3,
    DELIVERED: 4,
};

//-------------------------------------------------------------------------------------------------
/**
 * Диалог ошибки в формулы
 * Прерывает выполнение перевода заказа в статус PROCESSING
 */
const FormulaIsNotCorrect = ({ open, onApply, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>Ошибка</StyledDialogTitle>
            <DialogContent>
                <p>В заказе присутствуют позиции с некорректной формулой.</p>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onApply} autoFocus>
                    Игнорировать
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Отменить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//-------------------------------------------------------------------------------------------------
const SnsIsNotCorrectDialog = ({ open, onApply, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>Предупреждение</StyledDialogTitle>
            <DialogContent>
                <p>
                    В заказе присутствуют позиции которым выделено недостаточное количество серийных
                    номеров.
                </p>
                Вы хотите попробовать назначить серийные номера в автоматическом режиме?
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onApply} autoFocus>
                    Да
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Нет
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//-------------------------------------------------------------------------------------------------
const SnsAllocationError = ({ open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>Ошибка</StyledDialogTitle>
            <DialogContent>
                <p>Не удалось назначить серийные номера в автоматическом режиме.</p>
                Для продолжения необходимо исправить все ошибки в ручном режиме и повторить
                операцию.
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
/**
 *
 * @param {object} params - объект вида { order: , state_id: 0 }
 * @returns
 */
const SetOrderState = ({ params = null, onFinished }) => {
    const [formulaError, setFormulaError] = useState(false);
    const [wasShowFormulaError, setWasShowFormulaError] = useState(false); //Ошибка о корректности формулы была уже показана
    const [itemForSns, setItemForSns] = useState([]);
    const [showSnsError, setShowSnsError] = useState(false);

    useEffect(() => {
        if (params) {
            setWasShowFormulaError(false);
            runJob();
        }
    }, [params]);

    const errorHandler = useErrorHandler();

    const runJob = async () => {
        if (params) {
            //prettier-ignore
            switch (params.state_id) {
                case STATES.DELETE     : await toDelete();     break;
                case STATES.PREPARED   : await toPrepared();   break;
                case STATES.PROCESSING : await toProcessing(); break;
                case STATES.READY      : await toReady();      break;
                case STATES.DELIVERED  : await toDelivered();  break;
            }
        }
    };

    const toDelete = async () => {
        try {
            await OrderService.remove(params.order);
            onFinished();
        } catch (e) {
            errorHandler(e);
        }
    };

    const toPrepared = async () => {
        try {
            await OrderService.setState({ ...params.order, state_id: STATES.PREPARED });
            onFinished();
        } catch (e) {
            errorHandler(e);
        }
    };

    const toProcessing = async () => {
        try {
            /* здесь сначала нужно получить все позиции заказа, после чего сначала отфильтровать
             * позиции типа коса. Затем проверить на правильность их формул (в случае если хоть
             * одна позиция имеет некорректную формулу прервать показать ошибку) и только потом
             * проверить достаточность выделенных для позиций серийных номеров. В случае если где-то
             * мало или много выделенных серийных номеров показать предложение попробовать заполнить
             * автоматически. При ошибке автоматического выделения серийных номеров показать ошибку
             * и прервать процесс.
             */
            const response = await api.get(`order-items/${params.order.order_id}`);
            let items = response.data;
            /* Здесь в будущем возможно нужно будет вносит изменения, если вдруг в производстве
             * появятся приборы с формулой или серийными номерами
             */
            items = items.filter((i) => i.device_type === KOSA_DEVICE_TYPE);
            if (!wasShowFormulaError && items.some((i) => !i.correct_formula)) {
                //вывести сообщение, что есть позиция с некорректной формулой
                setWasShowFormulaError(true);
                setFormulaError(true);
                return;
            }
            if (items.some((i) => i.quantity !== i.sns.length)) {
                //вывести сообщение, что некорректное количество серийных номеров
                setItemForSns(items);
                return;
            }
            //проверить на достаточность выделенных серийных номеров
            await OrderService.setState({ ...params.order, state_id: STATES.PROCESSING });
            onFinished();
        } catch (e) {
            errorHandler(e);
        }
    };

    const toReady = async () => {
        try {
            await OrderService.setState({ ...params.order, state_id: STATES.READY });
            onFinished();
        } catch (e) {
            errorHandler(e);
        }
    };

    const toDelivered = async () => {
        try {
            await OrderService.setState({ ...params.order, state_id: STATES.DELIVERED });
            onFinished();
        } catch (e) {
            errorHandler(e);
        }
    };

    const snsAllocation = async () => {
        const assign = async (item) => {
            if (item.sns.length === 0) {
                const response = await KosaService.getNextId(); //возможно исключение???
                let nextSns = +response.data;
                const nSns = [];
                for (let i = 0; i < item.quantity; i++) {
                    nSns.push(nextSns++);
                }
                return KosaService.save({ ...item, sns: nSns });
            } else if (item.sns.length < item.quantity) {
                const nSns = [...item.sns];
                while (nSns.length < item.quantity) {
                    nSns.push(nSns.at(-1) + 1);
                }
                return KosaService.save({ ...item, sns: nSns });
            } else if (item.sns.length > item.quantity) {
                const nSns = item.sns.slice(0, item.quantity);
                return KosaService.save({ ...item, sns: nSns });
            }
            return Promise.resolve();
        };

        try {
            //выполняем последовательно, т.к. если запустить все вместе может получиться гонка за номерами
            for (const item of itemForSns) {
                await assign(item);
            }
            runJob();
        } catch (e) {
            setShowSnsError(true);
        } finally {
            setItemForSns([]);
        }
    };

    return (
        <>
            <FormulaIsNotCorrect
                open={formulaError}
                onApply={() => {
                    setFormulaError(false);
                    runJob();
                }}
                onClose={() => setFormulaError(false)}
            />
            <SnsIsNotCorrectDialog
                open={itemForSns.length}
                onApply={snsAllocation}
                onClose={() => setItemForSns([])}
            />
            <SnsAllocationError open={showSnsError} onClose={() => setShowSnsError(false)} />
        </>
    );
};

export default SetOrderState;
