import api from '../http';

export default class OrderService {
    /**
     * Асинхронно извлекает количество заказов, соответствующих заданному фильтру.
     *
     * @async
     * @function
     * @param {object} [filter={}] - Объект, содержащий критерии фильтрации для применения.
     * @param {number} filter.buyer_id - ID покупателя, по которому нужно отфильтровать заказы
     * @param {number} filter.state_id - ID статуса заказа, по которому нужно отфильтровать заказы
     * @param {number} filter.priority_id - ID приоритета заказа, по которому нужно отфильтровать заказы
     * @returns {Promise<number>} - Промис, который разрешается числом, представляющим количество заказов.
     */
    static async getOrdersSize(filter = {}) {
        return api.post(`/orders/size`, { filter });
    }

    /**
     * Получает заказы из API по указанным параметрам
     * @async
     * @param {number} start - Индекс первого заказа, который нужно вернуть
     * @param {number} size - Количество заказов, которое нужно вернуть
     * @param {object} [filter={}] - Объект фильтрации заказов
     * @param {number} filter.buyer_id - ID покупателя, по которому нужно отфильтровать заказы
     * @param {number} filter.state_id - ID статуса заказа, по которому нужно отфильтровать заказы
     * @param {number} filter.priority_id - ID приоритета заказа, по которому нужно отфильтровать заказы
     * @param {object} [sort={}] - Объект сортировки заказов
     * @param {string} sort.column - Колонка, по которой нужно отсортировать заказы
     * @param {string} sort.order - Порядок сортировки (ASC или DESC)
     * @returns {Promise<object>} Promise, который резолвится в объект с заказами
     */
    static async getOrders(start, size, filter = {}, sort = {}) {
        return api.post(`/orders`, { start, size, filter, sort });
    }

    /**
     * получить конкретный заказ
     * @param {Number} order_id - id нужного заказа
     * @returns {order_id, number, buyer_id, create_time, deadline_time, state_id,
     *          deleted, priority_id, comment} - информация по заказу
     */
    static async getOrder(order_id) {
        return api.get(`/orders/${order_id}`);
    }

    /**
     * Проверяет, занят ли указанный номер заказа
     * @param {String} number - номер заказа
     * @returns {Boolean} - true если номер заказа занят
     */
    static async checkOrdersNumber(number) {
        return api.post('/orders/check-number', { number });
    }

    /**
     * возможный номер следующего заказа
     * @returns сгенерированный номер следующей заявки
     */
    static async nextNumber() {
        return api.get('/orders/next-number');
    }

    /**
     * обновить данные заказа в бд
     * @param {{buyer_id, deadline_time, state_id,
     *      deleted, priority_id, comment, order_id}} order - объект заказа со всеми полями
     * @returns {Boolean} - true если успешно
     */
    static async update(order) {
        return api.post('/orders/update', { order });
    }

    /**
     * Создать новый заказ
     * @param {String} number - номер заказа
     * @param {Number} buyer_id - id заказчика
     * @returns {Number} - id созданного заказа
     */
    static async create(number, buyer_id) {
        return api.post('/orders/create', { number: number, buyer_id: buyer_id });
    }

    static async remove(order) {
        return api.delete(`/orders/${order.order_id}`);
    }

    static async setState(order) {
        return api.post(`/orders/status`, { order });
    }
}
