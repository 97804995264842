import { useContext, useState } from 'react';
import { Button, Link, Typography } from '@mui/material';

import { AuthContext } from '../../index';
import styles from './Login.module.css';
import { ReactComponent as Logo } from '../../img/logo.svg';
import useErrorHandler from '../../services/error-handler';
import { FocusableTextField } from '../StyledComponents';

/**
 * Сообщение об успешной регистрации
 * @param {string} login - логин
 * @param {Function} onClick - обработчик нажатия на кнопку "Вход"
 */
const RegistrationMessage = ({ login, onClick }) => {
    return (
        <div className={styles.form}>
            <div className={styles.inputArea}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.fields}>
                    <Typography>
                        Пользователь с логином <b>{login}</b>
                        <br />
                        успешно зарегистрирован и ожидает активации.
                        <br />
                        Для ускорения процесса активации рекомендуем
                        <br />
                        обратиться к администратору ресурса.
                    </Typography>
                </div>
            </div>
            <div className={styles.buttons}>
                <Link onClick={onClick}>Вход</Link>
            </div>
        </div>
    );
};

/**
 * @param onLoginClick - обработка нажатия на ссылку "Логин"
 * @param onRegisterClick - callback на событие успешной регистрации пользователя на сервере
 */
const RegistrationForm = ({ onLoginClick, onRegisterClick }) => {
    const { authStore } = useContext(AuthContext);

    const errorHandler = useErrorHandler();

    const [login, setLogin] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');

    const [loginError, setLoginError] = useState(' ');
    const [firstNameError, setFirstNameError] = useState(' ');
    const [lastNameError, setLastNameError] = useState(' ');
    const [passwordError, setPasswordError] = useState(' ');

    const onButtonClick = async () => {
        const lError = login.length < 1 ? 'Поле "Логин" не может быть пустым' : ' ';
        const fnError = firstName.length < 1 ? 'Поле "Имя" не может быть пустым' : ' ';
        const lnError = lastName.length < 1 ? 'Поле "Фамилия" не может быть пустым' : ' ';
        const passLength = password.length;
        let pError = ' ';
        if (passLength < 1) pError = 'Поле "Пароль" не может быть пустым';
        else if (passLength < 4) pError = 'Пароль должен быть больше 3 символов';
        setLoginError(lError);
        setFirstNameError(fnError);
        setLastNameError(lnError);
        setPasswordError(pError);
        if (lError.length < 2 && fnError.length < 2 && lnError.length < 2 && pError.length < 2) {
            try {
                if (await authStore.registration(login, firstName, lastName, password))
                    onRegisterClick(login);
            } catch (e) {
                errorHandler(e);
            }
        }
    };

    const onPasswordKeyDown = (event) => {
        if (event.key === 'Enter') onButtonClick();
    };

    return (
        <div className={styles.form}>
            <div className={styles.inputArea}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.fields}>
                    <FocusableTextField
                        label="Логин"
                        value={login}
                        onChange={(e) => {
                            setLogin(e.target.value);
                        }}
                        error={loginError.length > 2}
                        helperText={loginError}
                        type="text"
                        variant="outlined"
                    />
                    <FocusableTextField
                        label="Имя"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        error={firstNameError.length > 2}
                        helperText={firstNameError}
                        type="text"
                        variant="outlined"
                    />
                    <FocusableTextField
                        label="Фамилия"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        error={lastNameError.length > 2}
                        helperText={lastNameError}
                        type="text"
                        variant="outlined"
                    />
                    <FocusableTextField
                        label="Пароль"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        onKeyDown={onPasswordKeyDown}
                        error={passwordError.length > 2}
                        helperText={passwordError}
                        type="password"
                        variant="outlined"
                    />
                </div>
            </div>
            <div className={styles.buttons}>
                <Button variant="contained" onClick={onButtonClick}>
                    Регистрация
                </Button>
            </div>
            <div>
                <Link onClick={onLoginClick}>Вход</Link>
            </div>
        </div>
    );
};

const Registration = ({ onClick }) => {
    const [login, setLogin] = useState('');

    const onEnter = () => {
        setLogin('');
        onClick();
    };

    if (login.length) return <RegistrationMessage onClick={onEnter} login={login} />;
    else return <RegistrationForm onLoginClick={onEnter} onRegisterClick={(e) => setLogin(e)} />;
};

export default Registration;
