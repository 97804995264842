/**
 * Это старая версия SetOrderState.jsx
 * Здесь предполагалось, что можно было менять состояние для группы заказов
 * одновременно. К сожалению со временем это поведение ушло в прошлое и теперь
 * этот файл используется только для обработки удаления заказов
 * И честно говоря мне просто жаль его удалять, так как здесь вложено много сил
 * и представлена работа с промисами
 */

import { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import api from '../../http';
import OrderService from '../../services/orderService';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { StyledDialogTitle } from '../StyledComponents';
import { AdditionInfoContext } from '../ContentRoot/ContentRoot';

const KOSA_DEVICE_TYPE = 0;
const STATES = {
    DELETE: 0,
    PREPARED: 1,
    PROCESSING: 2,
    READY: 3,
    DELIVERED: 4,
};

const ToStr = ({ orders = [] }) => {
    const isSingle = orders.length === 1;
    return (
        <>
            {isSingle ? 'Заказ номер: ' : 'Заказы с номерами: '}
            {isSingle ? (
                <b>{orders[0].number} </b>
            ) : (
                orders.reduce((prev, order, i) => {
                    return [
                        ...prev,
                        <b key={order.order_id}>{order.number}</b>,
                        i < orders.length - 1 ? ', ' : ' ',
                    ];
                }, [])
            )}
            {isSingle ? 'имеет ' : 'имеют '}
        </>
    );
};

//=================================================================================================
const NotCorrectedDialog = ({ orders = [], onClose }) => {
    if (!orders.length) return null;
    return (
        <Dialog open={orders.length} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Предупреждение</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        <ToStr orders={orders} />
                        пункты с некорректной формулой или с не присвоенными серийными номерами.
                    </p>
                    Для продолжения исправьте все ошибки и попробуйте снова.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const NotThatStatusDialog = ({ orders = [], toState, onClose }) => {
    const { additionInformationStore } = useContext(AdditionInfoContext);
    if (!orders.length) return null;
    return (
        <Dialog open={orders.length} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Предупреждение</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        <ToStr orders={orders} /> статус не подходящий для перехода в статус "
                        <i>{additionInformationStore.statusStr(toState)}</i>".
                    </p>
                    Для продолжения исправьте все ошибки и попробуйте снова.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
const NotReadyDialog = ({ orders = [], onClose }) => {
    if (!orders.length) return null;
    return (
        <Dialog open={orders.length} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Предупреждение</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>
                    <p>
                        <ToStr orders={orders} /> позиции которые не готовы к отгрузке.
                    </p>
                    Для продолжения исправьте все ошибки и попробуйте снова.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose} autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

//=================================================================================================
/**
 * Процесс смены статуса заказа
 * @param {object} param - Объект с параметрами
 * @param {object} param.orders - Список объектов типа заказ которые будут переводится в новый статус
 * @param {number} param.state_id - Новый статус, для удаления заказа передать 0
 * @param {function} onClose - на самом деле смысл слегка другой, она вызывается сразу после получения параметров для обработки.
 * @param {function} onFinished - вызывается после завершения обработки
 */
const SetOrdersState = ({ param = { orders: [], state_id: 0 }, onFinished }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [notCorrect, setNotCorrect] = useState([]);
    const [notThatStatus, setNotThatStatus] = useState([]);
    const [notReady, setNotReady] = useState([]);

    useEffect(() => {
        if (param) {
            runJob();
        }
    }, [param]);

    const errorHandler = (e) => {
        if (e.isAxiosError) enqueueSnackbar(e.response.data.message, { variant: 'error' });
        else enqueueSnackbar(e.message, { variant: 'error' });
    };

    const runJob = async () => {
        if (param) {
            //prettier-ignore
            switch (param.state_id) {
                case STATES.DELETE     : await toDelete();     break;
                case STATES.PREPARED   : await toPrepared();   break;
                case STATES.PROCESSING : await toProcessing(); break;
                case STATES.READY      : await toReady();      break;
                case STATES.DELIVERED  : await toDelivered();  break;
            }
        }
    };

    //---------------------------------------------------------------------------------------------
    const toDelete = async () => {
        //здесь не должно быть ошибок, кроме связи, любой заказ можно удалить
        const promises = param.orders.map((order) => {
            return OrderService.remove(order);
        });
        await Promise.allSettled(promises).then((result) => {
            if (result.status === 'rejected') {
                errorHandler(result.reason);
            }
        });
        onFinished();
    };

    //---------------------------------------------------------------------------------------------
    const toPrepared = async () => {
        //здесь не должно быть ошибок, кроме связи, любой заказ можно перевести в PREPARED
        const promises = param.orders.map((order) => {
            return OrderService.setState({ ...order, state_id: STATES.PREPARED });
        });
        await Promise.allSettled(promises).then((result) => {
            if (result.status === 'rejected') {
                errorHandler(result.reason);
            }
        });
        onFinished();
    };

    //---------------------------------------------------------------------------------------------
    const toProcessing = async () => {
        const checkItem = (item) => {
            //функция проверки пункта заказа на корректность
            //prettier-ignore
            if (item.device_type     !== KOSA_DEVICE_TYPE) return true;
            if (item.correct_formula !== true) return false;
            if (item.quantity !== item.sns.length) return false;
            return true;
        };
        //здесь сложнее, получить все пункты всех заказов, проверить их на соответствие если все норм то перевести, если нет показать окно ошибки.
        const gPromises = param.orders.map((order) =>
            api
                .get(`order-items/${order.order_id}`)
                .then((response) => response.data)
                .then((items) => items.every((item) => checkItem(item)))
        );
        let corrected = [];
        await Promise.all(gPromises)
            .then((correct) => {
                corrected = correct;
            })
            .catch((e) => errorHandler(e));
        if (corrected.every((c) => c)) {
            const promises = param.orders.map((order) => {
                return OrderService.setState({ ...order, state_id: STATES.PROCESSING });
            });
            await Promise.allSettled(promises).then((result) => {
                if (result.status === 'rejected') {
                    errorHandler(result.reason);
                }
            });
            onFinished();
        } else {
            //показать ошибку
            const nCorrect = param.orders.filter((_, index) => !corrected[index]);
            setNotCorrect(nCorrect);
        }
    };

    //---------------------------------------------------------------------------------------------
    const toReady = async () => {
        //сначала проверяем все заказы на нахождение в правильном статусе
        const noThatState = param.orders.filter((order) => order.state_id === STATES.PREPARED);
        if (noThatState.length) {
            //выводим ошибку, что не все заказы в разрешенном статусе
            setNotThatStatus(noThatState);
        } else {
            //теперь нужно проверить что все пункты готовы к отгрузке
            const gPromises = param.orders.map((order) =>
                api
                    .get(`/kosa/order/${order.order_id}`)
                    .then((response) => response.data)
                    .then((items) => items.every((item) => item.state_id === 1))
            );
            let ready = [];
            await Promise.all(gPromises)
                .then((correct) => {
                    ready = correct;
                })
                .catch((e) => errorHandler(e));

            if (ready.every((c) => c)) {
                const promises = param.orders.map((order) => {
                    return OrderService.setState({ ...order, state_id: STATES.READY });
                });
                await Promise.allSettled(promises).then((result) => {
                    if (result.status === 'rejected') {
                        errorHandler(result.reason);
                    }
                });
                onFinished();
            } else {
                //показать ошибку
                const nReady = param.orders.filter((_, index) => !ready[index]);
                setNotReady(nReady);
            }
        }
    };

    const toDelivered = async () => {
        const notThatState = param.orders.filter(
            (order) => order.state_id !== STATES.READY && order.state_id !== STATES.DELIVERED
        );
        if (notThatState.length) {
            setNotThatStatus(notThatState);
        } else {
            const promises = param.orders.map((order) => {
                return OrderService.setState({ ...order, state_id: STATES.DELIVERED });
            });
            await Promise.allSettled(promises).then((result) => {
                if (result.status === 'rejected') {
                    errorHandler(result.reason);
                }
            });
            onFinished();
        }
    };

    return (
        <>
            <NotCorrectedDialog orders={notCorrect} onClose={() => setNotCorrect([])} />
            <NotThatStatusDialog
                orders={notThatStatus}
                toState={param?.state_id}
                onClose={() => setNotThatStatus([])}
            />
            <NotReadyDialog orders={notReady} onClose={() => setNotReady([])} />
        </>
    );
};

export default SetOrdersState;
