import { makeAutoObservable } from 'mobx';

import AdditionalInfoService from '../services/additional-info-service';

export default class AdditionInformationStore {
    priorities = []; //содержит данные таблицы приоритетов заказов
    statuses = []; //состояния выполнения заказа
    deviceTypes = []; //типы устройств

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        const priorities = AdditionalInfoService.priority();
        const statuses = AdditionalInfoService.orderState();
        const devTypes = AdditionalInfoService.deviceTypes();

        this.priorities = (await priorities).data;
        this.statuses = (await statuses).data;
        this.deviceTypes = (await devTypes).data;
    }

    /**
     * Находит тип устройства с заданным идентификатором и возвращает его название.
     * @param {number} id - Идентификатор типа устройства для поиска.
     * @returns {string} Название типа устройства с заданным идентификатором, или undefined, если не найден.
     */
    deviceType(id) {
        const type = this.deviceTypes.find((dt) => dt.type_id === id);
        return type ? type.name : undefined;
    }

    /**
     * статус заказа
     * @param {Number} statusId - id статуса
     * @returns {String} строку статуса заказа если он присутствует в списке, пустую строку если нет
     */
    statusStr(statusId) {
        const s = this.statuses.find((status) => status.state_id === statusId);
        return s ? s.state : '';
    }

    //prettier-ignore
    statusStyle(statusId) {
        switch (statusId) {
            case 2 : return { backgroundColor: '#ffffcd' };
            case 3 : return { backgroundColor: '#ebffe4' };
            case 4 : return { backgroundColor: '#d3f2ff' };
            default: return null;
        }
    }

    /**
     * Возвращает строку приоритета, связанную с заданным идентификатором приоритета.
     *
     * @param {number} priorityId - Идентификатор приоритета.
     * @returns {string} - Строка приоритета или пустая строка, если соответствующий приоритет не найден.
     */
    priorityStr(priorityId) {
        const p = this.priorities.find((priority) => priority.priority_id === priorityId);
        return p ? p.state : '';
    }
}
