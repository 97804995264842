import api from '../http';

export default class FormulaService {
    static async getFormula(order_item) {
        return api.post('/formula', { item: order_item });
    }

    static async save(formula) {
        return api.post('/formula/create-or-update', { formula });
    }

    static async remove(formula) {
        return api.post('/formula/remove', { formula });
    }

    static async getKosaTemplates() {
        return api.get('/formula/template/kosa');
    }

    static async getKosaTemplate(id) {
        return api.get(`/formula/template/kosa/${id}`);
    }

    static async createKosaTemplate(formula) {
        return api.post('/formula/template/kosa', { formula });
    }

    static async removeKosaTemplate(id) {
        return api.delete(`/formula/template/kosa/${id}`);
    }
}
