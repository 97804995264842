import api from '../http';

export default class AuthService {
    static async login(login, password) {
        return api.post('/user/login', { login, password });
    }

    static async registration(login, firstName, lastName, password) {
        return api.post('/user/registration', { login, firstName, lastName, password });
    }

    static async logout() {
        return api.post('/user/logout');
    }

    static async refresh() {
        return api.get('/user/refresh');
    }
}
