/**
 * Отображение списка заказов для пользователей с ролью "Пользователь"
 */
import { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import {
    Link,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';

import styles from './Orders.module.css';
import { AdditionInfoContext } from '../ContentRoot/ContentRoot';
import { OrderStore } from '../../store/orderStore';
import { StyledTableCell } from '../StyledComponents';

const WORKING_STATE_ID = 2;

const orderStore = new OrderStore();
const loadStore = async () => {
    try {
        await orderStore.setSort({ column: 'state_id', order: 'ASC' });
        await orderStore.setFilter({ state_id: WORKING_STATE_ID });
    } catch (e) {
        console.warn(e);
    }
};
loadStore();

//=================================================================================================

const OrderItem = observer(({ order }) => {
    const { additionInformationStore } = useContext(AdditionInfoContext);

    return (
        <TableRow hover sx={additionInformationStore.statusStyle(order.state_id)}>
            <TableCell>
                <Link href={`/orders/${order.order_id}`}>{order.number}</Link>
            </TableCell>
            <TableCell>{additionInformationStore.statusStr(order.state_id)}</TableCell>
            <TableCell>{DateTime.fromISO(order.deadline_time).toFormat('dd.MM.yyyy')}</TableCell>
            <TableCell>{additionInformationStore.priorityStr(order.priority_id)}</TableCell>
        </TableRow>
    );
});

//=================================================================================================
const OrdersUsr = () => {
    const onPageChange = (_, page) => {
        orderStore.setPage(page);
    };
    const onRowsPerPageChange = (event) => {
        orderStore.setPageSize(event.target.value);
    };

    const renderTableRows = () => {
        return orderStore.orders.map((order) => <OrderItem key={order.order_id} order={order} />);
    };

    const renderSkeletonRows = () => {
        const str = () => {
            return new Array(4).fill(
                <TableCell>
                    <Skeleton />
                </TableCell>
            );
        };
        return new Array(orderStore.pageSize).fill(<TableRow>{str()}</TableRow>);
    };

    return (
        <>
            <h2 className={styles.title}>Заказы</h2>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>№ Заказа</StyledTableCell>
                            <StyledTableCell>Статус</StyledTableCell>
                            <StyledTableCell>Срок</StyledTableCell>
                            <StyledTableCell>Приоритет</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderStore.loading ? renderSkeletonRows() : renderTableRows()}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20, 50]}
                                    component="div"
                                    count={orderStore.allOrdersSize}
                                    rowsPerPage={orderStore.pageSize}
                                    page={orderStore.page}
                                    onPageChange={onPageChange}
                                    onRowsPerPageChange={onRowsPerPageChange}
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default observer(OrdersUsr);
