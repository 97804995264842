import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ChromePicker } from 'react-color';

const ColorDialog = ({ color, open, onClose, onApply }) => {
    const [tColor, setTColor] = useState(color ? color : '#cecece');

    const onApplyClick = () => {
        onApply(tColor);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Выберите свет</DialogTitle>
            <DialogContent>
                <ChromePicker color={tColor} onChange={(c) => setTColor(c.hex)} disableAlpha />
            </DialogContent>
            <DialogActions>
                <Button onClick={onApplyClick} color="primary" variant="contained">
                    Выбрать
                </Button>
                <Button onClick={onClose}>Отмена</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ColorDialog;
