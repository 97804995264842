import { useMediaQuery } from '@mui/material';

const useMedia = () => {
    const isMobile = useMediaQuery('(max-width: 600px)');
    const isLaptop = useMediaQuery('(max-width: 1200px)');
    const isDesktop = useMediaQuery('(min-width: 1600px)');

    return {
        isMobile,
        isLaptop,
        isDesktop,
    };
};

export default useMedia;
