import { makeAutoObservable } from 'mobx';

import BuyersService from '../services/buyersService';

export default class BuyerListStore {
    buyers = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        const buyers = await BuyersService.idNameList();
        this.buyers = buyers.data;
    }

    /**
     * проверяет наличие заказчика с указанным именем
     * @param {String} name имя заказчика которое проверяем
     * @returns {Boolean} true если заказчик с указанным номером присутствует в списке
     */
    checkBuyer(name) {
        const b = this.buyers.findIndex((b) => b.name === name);
        return b > 0;
    }

    /**
     * имя заказчика
     * @param {Number} buyerId - id заказчика
     * @returns {String} Имя заказчика если он присутствует в списке, пустую строку если заказчик
     *      с таким id не найден
     */
    buyerName(buyerId) {
        const b = this.buyers.find((buyer) => buyer.buyer_id === buyerId);
        return b ? b.name : '';
    }
}
