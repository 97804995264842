import { makeAutoObservable } from 'mobx';
import OrderService from '../services/orderService';

export class OrderStore {
    loading = false;

    allOrdersSize = 0; //Всего заказов в таблице
    pageSize = 20; //Количество заказов отображаемое на одну страницу
    page = 0; //Номер активной страницы
    orders = []; //Список заказов
    filter = {}; //объект содержащий фильтры возможные поля: buyer_id, state_id, priority_id
    sort = {}; // объект сортировки содержит поля column(order_id, number, buyer_id, create_time, deadline_time, state_id, deleted, priority_id, comment), order(ASC, DESC)

    constructor() {
        makeAutoObservable(this);
    }

    async loadSize() {
        this.loading = true;
        try {
            const response = await OrderService.getOrdersSize(this.filter);
            this.allOrdersSize = +response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async loadOrders() {
        this.loading = true;
        try {
            const start = this.page * this.pageSize;
            const response = await OrderService.getOrders(
                start,
                this.pageSize,
                this.filter,
                this.sort
            );
            this.orders = response.data;
        } catch (e) {
            throw e;
        } finally {
            this.loading = false;
        }
    }

    async update(order) {
        const response = await OrderService.update(order);
        if (response.data) {
            await this.loadOrders();
        } else {
            throw new Error('Ошибка сохранения данных в бд');
        }
    }

    /**
     * Устанавливает фильтр для текущего объекта и загружает обновленный размер и заказы.
     *
     * @param {object} [filter={}] - Объект фильтрации заказов
     * @param {number} filter.buyer_id - ID покупателя, по которому нужно отфильтровать заказы
     * @param {number} filter.state_id - ID статуса заказа, по которому нужно отфильтровать заказы
     * @param {number} filter.priority_id - ID приоритета заказа, по которому нужно отфильтровать заказы
     */
    async setFilter(filter = {}) {
        this.filter = filter;
        await this.loadSize();
        await this.loadOrders();
    }

    /**
     * Устанавливает метод сортировки и загружает заказы.
     *
     * @param {object} [sort={}] - Объект сортировки заказов
     * @param {string} sort.column - Колонка, по которой нужно отсортировать заказы (order_id, number, buyer_id, create_time, deadline_time, state_id, deleted, priority_id, comment)
     * @param {string} sort.order - Порядок сортировки (ASC или DESC)
     */
    async setSort(sort = {}) {
        this.sort = sort;
        await this.loadOrders();
    }

    async setPage(page) {
        this.page = page;
        await this.loadOrders();
    }

    async setPageSize(size) {
        this.pageSize = size;
        await this.loadOrders();
    }

    async create(number, buyer_id) {
        const response = await OrderService.create(number, buyer_id);
        if (response.data) {
            await this.loadOrders();
            return response.data;
        } else {
            throw new Error('Ошибка создания заказа');
        }
    }
}
