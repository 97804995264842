import { DialogTitle, dialogTitleClasses, styled } from '@mui/material';

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    [`&.${dialogTitleClasses.root}`]: {
        backgroundColor: 'black',
        color: 'gray',
        marginBottom: '0.5em',
    },
}));

export default StyledDialogTitle;
