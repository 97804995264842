import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const BackButton = () => {
    const navigate = useNavigate();

    return (
        <Tooltip title="Назад">
            <IconButton
                disabled={window.history && window.history.length < 1}
                onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
            </IconButton>
        </Tooltip>
    );
};

export default BackButton;
