import api from '../http';

export default class KosaService {
    static async getNextId() {
        return api.get('/kosa');
    }

    static async getByOrderItemId(orderItemId) {
        return api.get(`/kosa/${orderItemId}`);
    }

    static async get(start, stop) {
        return api.get(`/kosa/${start}/${stop}`);
    }

    static async save(orderItem) {
        return api.post('/kosa', { orderItem: orderItem });
    }

    static async checkSns(sns) {
        return api.post('/kosa/check-sns', { sns: sns });
    }
}
