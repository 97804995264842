import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import {
    Alert,
    IconButton,
    Paper,
    Skeleton,
    Snackbar,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';

import { AdditionInfoContext } from '../ContentRoot/ContentRoot';
import OrderService from '../../services/orderService';
import { OrderItemStore } from '../../store/orderItemStore';
import Loading from '../Loading';
import { BackButton, StyledDataPicker, StyledTableCell } from '../StyledComponents';
import rangeToString from '../../services/range-string';

const itemStore = new OrderItemStore();

const OrderUsr = () => {
    const { additionInformationStore } = useContext(AdditionInfoContext);

    const [order, setOrder] = useState(null);
    const [loadError, setLoadError] = useState(''); //не вешать сюда больше ничего

    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    const getInfo = async () => {
        try {
            const order_id = params.id;
            await itemStore.setFilter({ order_id: order_id });
            const response = await OrderService.getOrder(order_id);
            setOrder(response.data);
        } catch (e) {
            console.log(e);
            setLoadError('Ошибка получения данных с сервера');
        }
    };
    useEffect(() => {
        getInfo();
    }, []);

    if (!order && loadError.length === 0) {
        return <Loading />; //Идет запрос... показываем затычку... В будущем можно переделать на скелет
    } else if (loadError.length > 0) {
        //Запрос закончился ошибкой
        return (
            <Snackbar open={true} autoHideDuration={30000}>
                <Alert severity="error">{loadError}</Alert>
            </Snackbar>
        );
    }

    const renderItems = () => {
        const renderShowDetailsButton = (item) => {
            if ('formula' in item) {
                if (item.formula === true) {
                    return (
                        <Tooltip title="Посмотреть детали">
                            <IconButton
                                color="info"
                                onClick={() =>
                                    navigate(
                                        `/formula/${item.device_type}/${item.order_id}/${item.item_id}`,
                                        { state: { backgroundLocation: location } }
                                    )
                                }>
                                <PreviewIcon />
                            </IconButton>
                        </Tooltip>
                    );
                }
            }
        };
        const renderDiapason = (item) => {
            if ('sns' in item) {
                return <Tooltip title="Серийные номера">{rangeToString(item.sns)}</Tooltip>;
            }
        };

        const items = itemStore.items;
        const result = items.map((item, index) => (
            <TableRow key={item.item_id} hover>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{additionInformationStore.deviceType(item.device_type)}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.comment}</TableCell>
                <TableCell align="right">
                    {renderDiapason(item)}
                    {renderShowDetailsButton(item)}
                </TableCell>
            </TableRow>
        ));
        return result;
    };

    const renderSkeletonItems = () => {
        const str = () => {
            const arr = new Array(5).fill(
                <TableCell>
                    <Skeleton />
                </TableCell>
            );
            arr.push(
                <TableCell>
                    <Stack direction="row" spacing={1} justifyContent="flex-end">
                        <Skeleton width={50} />
                        <Skeleton variant="circular" width={20} height={20} />
                    </Stack>
                </TableCell>
            );
            return arr;
        };
        return new Array(itemStore.pageSize).fill(<TableRow>{str()}</TableRow>);
    };

    return (
        <>
            <Stack spacing={2} padding={2}>
                <Stack direction="row">
                    <BackButton />
                    <Typography variant="h5" sx={{ marginTop: '0.2em' }}>
                        Заказ № {order.number}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <StyledDataPicker
                        label="Срок исполнения"
                        fullWidth
                        disabled
                        value={DateTime.fromISO(order.deadline_time)}
                    />
                    <TextField
                        label="Приоритет"
                        variant="outlined"
                        size="small"
                        disabled
                        value={additionInformationStore.priorityStr(order.priority_id)}
                    />
                    <TextField
                        label="Статус"
                        variant="outlined"
                        size="small"
                        disabled
                        value={additionInformationStore.statusStr(order.state_id)}
                    />
                </Stack>
                <TextField
                    label="Дополнительно"
                    variant="outlined"
                    size="small"
                    value={order.comment}
                    multiline
                    fullWidth
                    disabled
                />
            </Stack>

            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>№</StyledTableCell>
                            <StyledTableCell>Название</StyledTableCell>
                            <StyledTableCell>Тип</StyledTableCell>
                            <StyledTableCell>Количество</StyledTableCell>
                            <StyledTableCell>Примечание</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {itemStore.loading ? renderSkeletonItems() : renderItems()}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 20]}
                                    component="div"
                                    count={itemStore.allItemsSize}
                                    rowsPerPage={itemStore.pageSize}
                                    page={itemStore.page}
                                    onPageChange={(_, p) => itemStore.setPage(p)}
                                    onRowsPerPageChange={(e) =>
                                        itemStore.setPageSize(e.target.value)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
};

export default observer(OrderUsr);
