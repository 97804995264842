import { useContext, useEffect, useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    Stack,
    MenuItem,
    DialogActions,
} from '@mui/material';

import api from '../../http';
import AdditionalInfoService from '../../services/additional-info-service';
import FormulaService from '../../services/formula-service';
import { AuthContext } from '../..';
import { FocusableTextField, StyledDialogTitle } from '../StyledComponents';
import useErrorHandler from '../../services/error-handler';

class STypesStore {
    types = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load(device_type) {
        let loadFunction = null;
        //prettier-ignore
        switch(device_type){
            case 0 : loadFunction = AdditionalInfoService.kosaTypes;       break;
            case 1 : loadFunction = AdditionalInfoService.adcTypes;        break;
            case 16: loadFunction = AdditionalInfoService.loggerTypes;     break;
            case 18: loadFunction = AdditionalInfoService.controllerTypes; break;
            default: throw new Error('Неизвестный тип устройства');
        }
        const answer = await loadFunction();
        this.types = answer.data;
    }

    toStr(type) {
        const s = this.types.find((dt) => dt.type_id === type);
        return s ? s.name : '';
    }
}

const typeStore = new STypesStore();

//=================================================================================================

const renderTypeMenu = () => {
    return typeStore.types.map((dt) => (
        <MenuItem value={dt.type_id} key={dt.type_id}>
            {dt.name}
        </MenuItem>
    ));
};

//-------------------------------------------------------------------------------------------------
/**
 * Диалог создания новой позиции заказа для устройств типа АЦП Логгер Контроллер
 */
const NSimple = observer(({ item, onClose }) => {
    const [dType, setDType] = useState(null);
    const [quantity, setQuantity] = useState(1);

    const errorHandler = useErrorHandler();

    const onApplyClick = async () => {
        try {
            await api.post('/order-items/formula/simple', {
                order_id: item.order_id,
                device_type: item.device_type,
                quantity: quantity,
                type: dType,
                description: typeStore.toStr(dType),
            });
            onClose();
        } catch (e) {
            errorHandler(new Error('Ошибка сохранения позиции заказа'));
        }
    };

    const onQuantityKeyDown = (e) => {
        if (e.key === 'Enter') onApplyClick();
    };

    return (
        <Dialog open={item} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Добавления позиции заказа</StyledDialogTitle>
            <DialogContent>
                <Stack>
                    <FormControl margin="normal">
                        <InputLabel id="device_type">Модификация</InputLabel>
                        <Select
                            label="Модификация"
                            labelId="device_type"
                            variant="outlined"
                            value={dType}
                            onChange={(e) => setDType(e.target.value)}>
                            {renderTypeMenu()}
                        </Select>
                    </FormControl>
                    <FocusableTextField
                        id="quantity-textfield"
                        label="Количество"
                        size="small"
                        margin="normal"
                        sx={{ minWidth: '8em' }}
                        InputProps={{
                            inputProps: { min: 1, max: 10000, type: 'number' },
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        onKeyDown={onQuantityKeyDown}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onApplyClick} color="primary" variant="contained">
                    Сохранить
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
});

//-------------------------------------------------------------------------------------------------
/**
 * Диалог редактирования позиции заказа
 */
const ESimple = observer(({ item, isAdmin, onClose }) => {
    const [formula, setFormula] = useState(null);

    const errorHandler = useErrorHandler();

    const loadFormula = async () => {
        try {
            const f = await FormulaService.getFormula(item);
            setFormula(f.data);
        } catch (e) {
            console.warn(e);
            errorHandler(new Error('Ошибка загрузки данных'));
        }
    };

    useEffect(() => {
        if (item.item_id) loadFormula();
    }, [item]);

    const onApplyClick = async () => {
        try {
            await FormulaService.save(formula);
            onClose();
        } catch (e) {
            console.warn(e);
            errorHandler(new Error('Ошибка сохранения позиции'));
        }
    };

    if (!formula) return null;

    const isNotEditable = !isAdmin || formula.state_id > 1;

    return (
        <Dialog open={item} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Редактирование позиции заказа</StyledDialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="dev_type">Модификация</InputLabel>
                    <Select
                        label="Модификация"
                        labelId="dev_type"
                        variant="outlined"
                        value={formula.type_id}
                        disabled={isNotEditable}
                        onChange={(e) => setFormula({ ...formula, type_id: e.target.value })}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') onApplyClick();
                        }}>
                        {renderTypeMenu()}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onApplyClick}
                    color="primary"
                    variant="contained"
                    disabled={isNotEditable}>
                    Сохранить
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
});

//-------------------------------------------------------------------------------------------------
/**
 * Диалог отображения позиции заказа
 */
const VSimple = observer(({ item, onClose }) => {
    const [formula, setFormula] = useState(null);

    const errorHandler = useErrorHandler();

    const loadFormula = async () => {
        try {
            const f = await FormulaService.getFormula(item);
            setFormula(f.data);
        } catch (e) {
            console.warn(e);
            errorHandler(new Error('Ошибка загрузки данных'));
        }
    };

    useEffect(() => {
        if (item.item_id) loadFormula();
    }, [item]);

    if (!formula) return null;

    return (
        <Dialog open={item} onClose={onClose} disableRestoreFocus>
            <StyledDialogTitle>Позиция заказа</StyledDialogTitle>
            <DialogContent>
                Модификация: <b>{typeStore.toStr(formula.type_id)}</b>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={onClose} autoFocus>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
});

//=================================================================================================
const Simple = ({ item, onClose }) => {
    const { authStore } = useContext(AuthContext);

    const errorHandler = useErrorHandler();

    useEffect(() => {
        if (item) {
            loadTypes();
        }
    }, [item]);

    const loadTypes = async () => {
        try {
            await typeStore.load(item.device_type);
        } catch (e) {
            errorHandler(e);
        }
    };

    const isAdmin = authStore.user.role === 2;

    return isAdmin ? (
        item.item_id ? (
            <ESimple item={item} isAdmin={isAdmin} onClose={onClose} />
        ) : (
            <NSimple item={item} onClose={onClose} />
        )
    ) : (
        <VSimple item={item} onClose={onClose} />
    );
};

export default Simple;
