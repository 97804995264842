import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, TextField } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';

import styles from './CreateOrderDialog.module.css';
import useErrorHandler from '../../services/error-handler';
import OrderService from '../../services/orderService';
import CreateBuyersDialog from '../Buyers/CreateBuyersDialog';
import { FocusableTextField, StyledAutocomplete, StyledDialogTitle } from '../StyledComponents';

const NotBuyerDialog = ({ open, onApply, onClose, buyerName }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <StyledDialogTitle>Несуществующий покупатель</StyledDialogTitle>
            <DialogContent>
                <p>
                    Веденное имя покупателя: "{buyerName}" не соответствует ни одному из
                    существующих.
                </p>
                Создать нового покупателя?
            </DialogContent>
            <DialogActions>
                <Button onClick={onApply} variant="contained">
                    Создать
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

/**
 * Диалог создания нового заказа
 * @param {Boolean} open - отобразить диалог
 * @param {Array<{buyer_id, name}>} buyersList - массив покупателей
 * @param {Function} onApply(orderNum, buyersId) - срабатывает при клике на создать
 */
const CreateOrderDialog = ({ open, buyersList, onApply, onClose }) => {
    const [orderNum, setOrderNum] = useState('');
    const [buyer, setBuyer] = useState('');

    const [orderNumError, setOrderNumError] = useState(' ');
    const [buyerError, setBuyerError] = useState(' ');

    const [showCBDialog, setShowCBDialog] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);

    const errorHandler = useErrorHandler();

    //получение номера заказа
    const getOrderNum = async () => {
        if (!open) return;
        try {
            const res = await OrderService.nextNumber();
            setOrderNum(res.data);
        } catch (e) {
            errorHandler(e);
        }
    };
    //сброс при закрытии
    const reset = () => {
        if (open) return;
        setOrderNum('');
        setBuyer('');
        setOrderNumError(' ');
        setBuyerError(' ');
    };

    useEffect(() => {
        getOrderNum();
        reset();
    }, [open]);

    const checkOrdersNumber = async (number) => {
        const res = await OrderService.checkOrdersNumber(number);
        return res.data;
    };

    const behaverSetOrderNum = async (event) => {
        const val = event.target.value;
        setOrderNum(val);
        if (val.length === 0) {
            setOrderNumError('Поле не может быть пустым');
            return;
        }
        try {
            const ch = await checkOrdersNumber(val);
            if (ch) {
                setOrderNumError('Номер уже занят');
                return;
            }
        } catch (e) {
            errorHandler(e);
        }
        setOrderNumError(' ');
    };

    const behaverSetBuyer = (event, value) => {
        setBuyer(value);
        value.length === 0 ? setBuyerError('Поле не может быть пустым') : setBuyerError(' ');
    };

    const onNewBuyerCreated = (buyer) => {
        behaverSetBuyer(null, buyer.name);
        setShowCBDialog(false);
    };

    const onApplyButtonClick = async () => {
        if (orderNumError.length > 1 || buyerError.length > 1) return;
        const b = buyersList.find((b) => b.name === buyer);
        if (b) {
            try {
                const ch = await checkOrdersNumber(orderNum);
                if (ch) {
                    setOrderNumError('Упс номер уже занят');
                    return;
                }
            } catch (e) {
                errorHandler(e);
            }
            onApply(orderNum, b.buyer_id);
        } else {
            setShowQuestion(true);
        }
    };

    const onKeyDown = (event) => {
        if (event.key === 'Enter') onApplyButtonClick();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                PaperProps={{ style: { overflowY: 'auto' } }}>
                <StyledDialogTitle>Создать новый заказ</StyledDialogTitle>
                <DialogContent>
                    <FocusableTextField
                        label="Номер заказа"
                        fullWidth
                        value={orderNum}
                        error={orderNumError.length > 1}
                        helperText={orderNumError}
                        onChange={behaverSetOrderNum}
                        onKeyDown={onKeyDown}
                        margin="normal"
                    />
                    <div className={styles.buyerArea}>
                        <StyledAutocomplete
                            fullWidth
                            freeSolo
                            inputValue={buyer}
                            onInputChange={behaverSetBuyer}
                            onKeyDown={onKeyDown}
                            options={buyersList}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Заказчик"
                                    margin="normal"
                                    error={buyerError.length > 1}
                                    helperText={buyerError}
                                />
                            )}
                        />
                        <IconButton onClick={() => setShowCBDialog(true)} sx={{ marginTop: -2 }}>
                            <AddBoxIcon color="success" sx={{ width: 40, height: 40 }} />
                        </IconButton>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="success" onClick={onApplyButtonClick} variant="contained">
                        Создать
                    </Button>
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
            <NotBuyerDialog
                open={showQuestion}
                onClose={() => setShowQuestion(false)}
                onApply={() => {
                    setShowQuestion(false);
                    setShowCBDialog(true);
                }}
                buyerName={buyer}
            />
            <CreateBuyersDialog
                open={showCBDialog}
                onApply={onNewBuyerCreated}
                onClose={() => setShowCBDialog(false)}
                bayerName={buyer}
            />
        </>
    );
};

export default CreateOrderDialog;
