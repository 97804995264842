import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { FocusableTextField } from '../StyledComponents';

/**
 * user - данные пользователя для которого вызван диалог.
 * если user = null or undefined то диалог не отображается.
 * onCancel - функция закрытия диалога
 * onApply(newPassword) - функция обратного вызова для кнопки применить
 */
const PasswordDialog = ({ user, onCancel, onApply }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(' ');

    if (!user) return;

    const onApplyButtonClick = () => {
        if (password.length < 4) {
            setError('Пароль менее 4-х символов');
            return;
        } else {
            setError(' ');
            onApply(password);
            setPassword('');
        }
    };

    const onClose = () => {
        setPassword('');
        onCancel();
    };
    const onPasswordKeyDown = (event) => {
        if (event.key === 'Enter') onApplyButtonClick();
    };

    return (
        <Dialog open={Boolean(user)} onClose={onClose}>
            <DialogTitle>Смена пароля</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Смена пароля для пользователя: {user.login} <br />
                </DialogContentText>
                <FocusableTextField
                    autoFocus
                    margin="dense"
                    // variant="outlined"
                    variant="standard"
                    type="password"
                    label="Новый пароль"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={onPasswordKeyDown}
                    error={error.length > 2}
                    helperText={error}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onApplyButtonClick}>
                    Применить
                </Button>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordDialog;
