import api from '../http';

export default class OrderItemsService {
    /**
     * Возвращает количество элементов заказа, соответствующих заданным критериям фильтрации.
     *
     * @param {Object} [filter={}] - Опциональный объект фильтрации для уточнения поиска.
     * @param {number} filter.order_id - ID заказа.
     * @param {number} filter.device_type - ID типа устройства.
     * @param {number} filter.state_id - ID состояния.
     * @param {number} filter.buyer_id - ID покупателя.
     * @returns {Promise<number>} - Промис, который разрешается в количество элементов заказа, соответствующих фильтру.
     */
    static async getSize(filter = {}) {
        return api.post('/order-items/size', { filter });
    }

    /**
     * Асинхронно извлекает список элементов с сервера.
     *
     * @param {number} start - Индекс, начиная с которого нужно извлечь элементы.
     * @param {number} size - Количество элементов, которые нужно извлечь.
     * @param {Object} [filter={}] - Объект с фильтром для получения элементов.
     * @param {number} filter.order_id - ID заказа.
     * @param {number} filter.device_type - ID типа устройства.
     * @param {number} filter.state_id - ID состояния.
     * @param {number} filter.buyer_id - ID покупателя.
     * @param {Object} [sort={}] - Объект с параметрами сортировки элементов.
     * @param {string} sort.column - Название столбца для сортировки.
     * @param {string} sort.order - Направление сортировки. 'ASC' для сортировки по возрастанию; 'DESC' для сортировки по убыванию.
     * @return {Promise} Promise, который разрешается в список элементов.
     */
    static async getItems(start, size, filter = {}, sort = {}) {
        return api.post('/order-items', { start, size, filter, sort });
    }

    static async add(order_id, type_id, description, comment = '', quantity = 1) {
        return api.post('/order-items/add', {
            order_id: order_id,
            device_type: type_id,
            description: description,
            comment: comment,
            quantity: quantity,
        });
    }

    static async update(orderItem) {
        return api.post('/order-items/update', { item: orderItem });
    }

    static async delete(orderItem) {
        return api.post('/order-items/delete', { item: orderItem });
    }
}
