import api from '../http';

export default class AdditionalInfoService {
    /**
     * Таблица приоритетов (Низкий, Обычный...)
     * @returns [{priority_id, state}]
     */
    static async priority() {
        return api.get('/additional/priority');
    }

    /**
     * Таблица состояний заказа (В обработке, В работе, Готов...)
     * @returns [{state_id, state}]
     */
    static async orderState() {
        return api.get('/additional/order-state');
    }

    /**
     * Таблица крышек для косы
     * @returns [{cup_id, name, weight}]
     */
    static async cap() {
        return api.get('/additional/k-cap');
    }

    /**
     * Таблица катушек для косы
     * @returns [{reel_id, name, weight}]
     */
    static async reel() {
        return api.get('/additional/k-reel');
    }

    /**
     * Таблица типов устройств
     * @returns [{type_id, name, short_name}]
     */
    static async deviceTypes() {
        return api.get('/additional/device-types');
    }

    /**
     * Таблица типов кос
     * @returns [{type_id, name, short_name}]
     */
    static async kosaTypes() {
        return api.get('/additional/kosa-types');
    }

    /**
     * Таблица типов АЦП устройств
     * @returns [{type_id, name, short_name}]
     */
    static async adcTypes() {
        return api.get('/additional/adc-types');
    }

    /**
     * Таблица типов логгеров
     * @returns [{type_id, name, short_name}]
     */
    static async loggerTypes() {
        return api.get('/additional/logger-types');
    }

    /**
     * Таблица типов контроллеров
     * @returns [{type_id, name, short_name}]
     */
    static async controllerTypes() {
        return api.get('/additional/controller-types');
    }
}
