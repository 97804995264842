import { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';

import { BuyerListContext } from '../Orders';
import BuyersService from '../../services/buyersService';
import { StyledDialogTitle } from '../StyledComponents';

/**
 * Диалог создания нового заказчика
 * @param {Boolean} open - отобразить диалог
 * @param {Function} onApply(name, buyers_id) - срабатывает при создании заказчика
 */
const CreateBuyersDialog = ({ open, onClose, onApply, bayerName = '' }) => {
    const { buyerListStore } = useContext(BuyerListContext);

    const [buyersName, setBuyerName] = useState('');
    const [buyerInn, setBuyerInn] = useState('');
    const [buyerNameError, setBuyerNameError] = useState(' ');

    useEffect(() => {
        if (!open) {
            setBuyerName('');
            setBuyerNameError(' ');
            setBuyerInn('');
        } else {
            setBuyerName(bayerName);
        }
    }, [open]);

    if (!open) return;

    const checkName = (name) => {
        return buyerListStore.checkBuyer(name);
    };

    const inn = () => {
        return buyerInn.length === 0 ? null : buyerInn;
    };

    const behaverSetBuyerName = async (event) => {
        const val = event.target.value;
        setBuyerName(val);
        if (val.length === 0) {
            setBuyerNameError('Поле не может быть пустым');
            return;
        }
        if (checkName(val)) {
            setBuyerNameError('Уже занято');
            return;
        }
        setBuyerNameError(' ');
    };

    const onApplyButtonClick = async () => {
        if (buyerNameError.length > 1) return;
        try {
            const res = await BuyersService.create(buyersName, inn());
            if (res.data.name === buyersName) {
                onApply(res.data);
            }
        } catch (e) {
            setBuyerNameError('Уфф ошибочка...');
            console.log(e);
        } finally {
            try {
                await buyerListStore.load();
            } catch (e) {
                console.warn(e);
            }
        }
    };

    const onTextFieldOnButtonDown = (event) => {
        if (event.key === 'Enter') onApplyButtonClick();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <StyledDialogTitle>Новый заказчик</StyledDialogTitle>
            <DialogContent>
                <TextField
                    label="Имя заказчика"
                    value={buyersName}
                    error={buyerNameError.length > 1}
                    helperText={buyerNameError}
                    onChange={behaverSetBuyerName}
                    onKeyDown={onTextFieldOnButtonDown}
                    margin="normal"
                    fullWidth
                    autoFocus
                />
                <TextField
                    label="ИНН"
                    value={buyerInn}
                    onChange={(e) => setBuyerInn(e.target.value)}
                    onKeyDown={onTextFieldOnButtonDown}
                    margin="normal"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button color="success" onClick={onApplyButtonClick} variant="contained">
                    Создать
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateBuyersDialog;
