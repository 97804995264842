import styled from '@emotion/styled';
import {
    TablePagination,
    iconButtonClasses,
    tablePaginationClasses,
    toolbarClasses,
} from '@mui/material';

const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
    [`&.${tablePaginationClasses.root}`]: {
        fontSize: '0.75rem',
        overflow: 'visible',
    },
    [`&.${tablePaginationClasses.toolbar}`]: {
        overflow: 'auto',
        paddingLeft: '0px',
    },
    [`& .${tablePaginationClasses.selectLabel}`]: {
        fontSize: '0.75rem',
    },
    [`& .${tablePaginationClasses.displayedRows}`]: {
        fontSize: '0.75rem',
    },
    [`& .${tablePaginationClasses.actions}`]: {
        marginLeft: '0px',
    },
    [`& .${toolbarClasses.root}`]: {
        paddingLeft: '0px',
    },
    [`& .${iconButtonClasses.root}`]: {
        padding: '3px',
    },
}));

export default StyledTablePagination;
