import { Route, Routes } from 'react-router-dom';
import Formula from './Formula';

const FormulaRouters = () => {
    return (
        <Routes>
            <Route path="/:device_type/:order_id/:item_id" element={<Formula />} />
            <Route path="/:device_type/:order_id" element={<Formula />} />
        </Routes>
    );
};

export default FormulaRouters;
