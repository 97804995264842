import { TableCell, tableCellClasses, styled } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#313338',
        color: theme.palette.common.white,
        fontWeight: 700,
    },
}));

export default StyledTableCell;
