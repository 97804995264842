import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Kosa from './Kosa';
import Other from './Other';
import Simple from './Simple';

/**
 * Отображает диалог формулы устройства
 * Параметры формулы передаются в адресную строку
 * @param {Number} device_type - тип устройства формулу которого нужно отобразить
 * @param {Number} order_id - идентификатор заказа
 * @param {Number} item_id - идентификатор позиции заказа (необязательный параметр)
 *    если не передан - отображается как окно создания позиции заказа.
 *
 * при закрытии браузер возвращается на страницу с которой была открыта формула, при этом в
 * параметре state объекта location можно отследить параметр refresh его можно использовать
 * для запуска фонового обновления данных страницы.
 * Если формула была открыта в новом окне(вкладке) браузера, то при закрытии диалога вы автоматически
 * отправляетесь на страницу заказа к которому относится формула.
 */
const Formula = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();

    //prettier-ignore
    const item = {
        device_type : +params.device_type,
        order_id    : +params.order_id,
        item_id     :  params.item_id ? +params.item_id : null
    };

    const onClose = () => {
        const address = location.state
            ? location.state.backgroundLocation.pathname
            : '/orders/' + item.order_id;
        navigate(address, { state: { refresh: true }, replace: true });
    };

    const render = () => {
        //prettier-ignore
        switch (item.device_type){
            case -1 : return <Other item={item} onClose={onClose} />;
            case 0  : return <Kosa item={item} onClose={onClose} />;
            case 1  :
            case 16 :
            case 18 : return <Simple item={item} onClose={onClose} />;
            default : return null;
        }
    };

    return render();
};

export default Formula;
