/**
 * Возвращает строку, содержащую диапазоны чисел из переданного массива
 * @param {number[]} numbers - числовой массив, должен быть заранее отсортирован
 * @returns
 */
const rangeToString = (numbers) => {
    if (numbers.length === 0) return '';

    let ranges = [];
    let start = null;
    let end = null;

    const push = () => {
        if (start === end) ranges.push(start.toString());
        else ranges.push(`${start}-${end}`);
    };

    for (let i = 0; i < numbers.length; i++) {
        if (start === null) {
            start = numbers[i];
            end = numbers[i];
        } else if (numbers[i] === end + 1) {
            end = numbers[i];
        } else {
            push();
            start = numbers[i];
            end = numbers[i];
        }
    }
    push();

    return ranges.join(', ');
};

export default rangeToString;
