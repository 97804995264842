import { ThemeProvider, createStyles } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const style = createStyles({
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    padding: '0px',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '7px',
                    paddingLeft: '15px',
                },
            },
        },
    },
});
const StyledDataPicker = (props) => {
    return (
        <ThemeProvider theme={style}>
            <DatePicker {...props} sx={{ maxWidth: '170px', minWidth: '150px' }} />
        </ThemeProvider>
    );
};

export default StyledDataPicker;
