import styled from '@emotion/styled';
import { Autocomplete, Paper } from '@mui/material';

const CustomPaper = styled(Paper)(() => ({
    boxShadow: 'inset 1px 1px 4px #000, 1px 1px 4px #000',
}));

const StyledAutocomplete = (props) => {
    return <Autocomplete {...props} PaperComponent={CustomPaper} />;
};

export default StyledAutocomplete;
