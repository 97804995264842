import { useContext } from 'react';

import { AuthContext } from '../../index';
import OrderAdm from './OrderAdm';
import OrderUsr from './OrderUsr';

const Order = () => {
    const { authStore } = useContext(AuthContext);

    const isAdmin = authStore.user.role === 2;

    return <>{isAdmin ? <OrderAdm /> : <OrderUsr />}</>;
};

export default Order;
