import { Route, Routes } from 'react-router-dom';
import Buyer from './Buyer';

const BuyerRoutes = () => {
    return (
        <Routes>
            <Route path="/:id" element={<Buyer />} />
        </Routes>
    );
};

export default BuyerRoutes;
