import { useState } from 'react';
import {
    Button,
    Menu,
    MenuItem,
    IconButton,
    Stack,
    TextField,
    Tooltip,
    Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//=================================================================================================
//поле additional представляет собой массив объектов каждый из которых имеет вид:
// {
//    type: String - тип объекта,
//    value: any - значение поля
// }
// type может принимать значения:
//      1. telephone - номер телефона организации {type: 'telephone', value: '+7916....'}
//      2. email - адрес электронной почты организации
//      3. address - адрес организации/контакта
//      3. contact - контакт в данной организации, при этом контакт в value в свою очередь содержит
//          массив возможных полей:
//              3.1 name - имя обязательное поле
//              3.2 telephone
//              3.3 email
// пример объекта additional:
//      [
//          {type: 'telephone', value: '+7(916)805-22-34'},     // 1 телефон организации
//          {type: 'telephone', value: '+7(916)805-22-35'},     // 2 телефон организации
//          {type: 'email', value: 'simple@email.ru'},          // адрес электронной почты
//          {type: 'address', value: 'ул. Пушкина, д.3'},        // адрес организации
//          {type: 'contact', value: [
//                  {type: 'name', value: 'Менеджер по закупкам'},
//                  {type: 'telephone', value: '+7916805-22-36'},
//                  {type: 'email', value: 'me@email.ru'}
//                  {type: 'address', value: 'ул. Пушкина, д.3'}
//          ]}
//      ]
//
//=================================================================================================

/**
 * функция сравнения 2 полей
 * @param {additionalField} a - левое поле
 * @param {additionalField} b -правое поле
 */
const compare = (a, b) => {
    const types = ['name', 'telephone', 'email', 'address', 'contact'];
    const indexA = types.indexOf(a.type);
    const indexB = types.indexOf(b.type);
    return indexA - indexB;
};

//=================================================================================================
/**
 * Отрисовка поля дополнительной информации
 * @param {type, value} info - поле информации для отображения
 * @param {Function} onChange(additionItem) - при изменении состояния
 * @param {Function} onDelete() - нажали кнопку удалить
 * @returns Поле для дополнительной информации
 */
const AdditionalItem = ({ info, onChange, onDelete }) => {
    const [value, setValue] = useState(info.value);

    const typeToStr = () => {
        switch (info.type) {
            case 'telephone':
                return 'Телефон';
            case 'email':
                return 'Почта';
            case 'address':
                return 'Адрес';
            case 'name':
                return 'Контакт';
            default:
                return 'Неизвестно';
        }
    };

    return (
        <Stack spacing={2} direction="row">
            <TextField
                label={typeToStr(info.type)}
                value={value}
                size="small"
                onChange={(e) => setValue(e.target.value)}
                onBlur={(e) => onChange({ ...info, value: e.target.value })}
                fullWidth
            />
            <Tooltip title="Удалить поле">
                <IconButton color="secondary" onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

//=================================================================================================
/**
 * отрисовка полей контакта
 * @param {[contact]} contacts - поле одного контакта
 * @param {Function} onChange(contact) - при изменении поля
 */
const AdditionalContact = ({ contact, onChange }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const onValueChange = (index, value) => {
        let contactVal = [...contact.value];
        contactVal[index] = value;
        onChange({ ...contact, value: contactVal });
    };
    const onDeleteClick = (index, type) => {
        if (type === 'name') {
            onChange(null);
            return;
        } else {
            let contactVal = [...contact.value];
            contactVal.splice(index, 1);
            onChange({ ...contact, value: contactVal });
        }
    };
    const onAddField = (type) => {
        const items = [...contact.value];
        items.push({ type: type, value: '' });
        items.sort(compare);
        onChange({ ...contact, value: items });
        setMenuAnchor(null);
    };

    const renderValues = () => {
        return contact.value.map((item, index) => (
            <AdditionalItem
                key={index}
                info={item}
                onChange={onValueChange.bind(undefined, index)}
                onDelete={() => onDeleteClick(index, item.type)}
                marginLeft={item.type === 'contact' ? 2 : 4}
            />
        ));
    };

    return (
        <Paper elevation={3} sx={{ padding: 2 }}>
            <Button variant="contained" onClick={(e) => setMenuAnchor(e.currentTarget)}>
                Добавить поле
                <ArrowDropDownIcon />
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MenuItem onClick={() => onAddField('telephone')}>Телефон</MenuItem>
                <MenuItem onClick={() => onAddField('email')}>Email</MenuItem>
                <MenuItem onClick={() => onAddField('address')}>Адрес</MenuItem>
            </Menu>
            <Stack spacing={2} padding={2}>
                {renderValues()}
            </Stack>
        </Paper>
    );
};

//=================================================================================================
/**
 * рисует поля в зависимости от их типа
 * @param additionalItem
 * @param {Function} - onChange(value)
 */
const AdditionalSwitch = ({ additionalItem, onChange }) => {
    if (additionalItem.type === 'contact')
        return <AdditionalContact contact={additionalItem} onChange={onChange} />;
    else
        return (
            <AdditionalItem
                info={additionalItem}
                onChange={onChange}
                onDelete={() => onChange(null)}
            />
        );
};

//=================================================================================================
/**
 * принимает массив и рисует его поля
 * @param {[additional`s]} additional
 * @param {Function} onChange(value)
 */
const Additional = ({ additional, onChange }) => {
    const [menuAnchor, setMenuAnchor] = useState(null);

    const sort = (additional) => {
        const out = [...additional];
        out.sort(compare);
        return out;
    };

    const onChangeItem = (index, val) => {
        let newInfo = sort(additional);
        if (val === null) {
            newInfo.splice(index, 1);
        } else {
            newInfo.splice(index, 1, val);
        }
        onChange(newInfo);
    };

    const onAddField = (type) => {
        const ad = [...additional];
        ad.push({ type: type, value: '' });
        onChange(ad);
    };
    const onAddContactField = () => {
        const ad = [...additional];
        ad.push({ type: 'contact', value: [{ type: 'name', value: '' }] });
        onChange(ad);
    };

    const renderItems = () => {
        const ad = sort(additional);
        return ad.map((item, index) => (
            <AdditionalSwitch
                key={index}
                additionalItem={item}
                onChange={onChangeItem.bind(undefined, index)}
            />
        ));
    };

    return (
        <>
            <Button variant="contained" onClick={(e) => setMenuAnchor(e.currentTarget)}>
                Добавить поле
                <ArrowDropDownIcon />
            </Button>
            <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={() => setMenuAnchor(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MenuItem onClick={() => onAddField('telephone')}>Телефон</MenuItem>
                <MenuItem onClick={() => onAddField('email')}>Email</MenuItem>
                <MenuItem onClick={() => onAddField('address')}>Адрес</MenuItem>
                <MenuItem onClick={onAddContactField}>Контакт</MenuItem>
            </Menu>
            <Stack spacing={2} padding={2}>
                {renderItems()}
            </Stack>
        </>
    );
};

export default Additional;
