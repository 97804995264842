import { useContext, useEffect, useState } from 'react';
import { makeAutoObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Stack,
    TextField,
} from '@mui/material';

import { AuthContext } from '../..';
import api from '../../http';
import OrderItemsService from '../../services/orderItemsService';
import { FocusableTextField, StyledAutocomplete } from '../StyledComponents';

class OthersInfoStore {
    names = [];
    descriptions = [];

    constructor() {
        makeAutoObservable(this);
    }

    async load() {
        await this.loadNames();
        await this.loadDescriptions();
    }

    async loadNames() {
        const response = await api.get('/order-items/other/names');
        this.names = response.data;
    }

    async loadDescriptions() {
        const response = await api.get('/order-items/other/comments');
        this.descriptions = response.data;
    }
}

const othersInfoStore = new OthersInfoStore();
const loadInfo = async () => {
    try {
        await othersInfoStore.load();
    } catch (e) {
        console.warn(e);
    }
};
loadInfo();

/**
 * Other по сути диалоговое окно создания позиции заказа, которое не имеет своей формулы
 * -> из этого вытекает, что данная позиция имеет "наименование", "описание" и "количество",
 * и не может быть открыто для редактирования, так как все эти данные и так доступны для
 * редактирования в таблице "Позиции заказа"
 */
const Other = ({ item, onClose }) => {
    const { authStore } = useContext(AuthContext);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [quantity, setQuantity] = useState(1);

    const [error, setError] = useState('');

    useEffect(() => {
        if (item) {
            setName('');
            setDescription('');
            setQuantity(1);
        }
    }, [item]);

    const onApplyButtonClick = async () => {
        try {
            await OrderItemsService.add(item.order_id, -1, name, description, quantity);
            onClose();
        } catch (e) {
            console.warn(e);
            setError('Ошибка сохранения позиции заказа');
        }
    };

    const onNameKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            const nextInputElement = document.getElementById('description-textfield');
            nextInputElement.focus();
            setName(e.target.value);
        }
    };

    const onDescriptionKeyDown = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault();
            const nextInputElement = document.getElementById('quantity-textfield');
            nextInputElement.focus();
            setDescription(e.target.value);
        }
    };

    const onQuantityKeyDown = (e) => {
        if (e.key === 'Enter') onApplyButtonClick();
    };

    if (authStore.user.role !== 2) return null; //Только администратор может создать позицию заказа

    return (
        <>
            <Dialog open={item} onClose={onClose} disableRestoreFocus fullWidth maxWidth="sm">
                <DialogTitle sx={{ backgroundColor: 'black', color: 'gray' }}>
                    Добавить позицию заказа
                </DialogTitle>
                <DialogContent>
                    <Stack>
                        <StyledAutocomplete
                            freeSolo
                            inputValue={name}
                            onInputChange={(_, v) => setName(v)}
                            options={othersInfoStore.names}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    autoFocus
                                    label="Наименование"
                                    margin="normal"
                                    size="small"
                                    onKeyDown={onNameKeyDown}
                                />
                            )}
                        />
                        <StyledAutocomplete
                            id="description-textfield"
                            freeSolo
                            inputValue={description}
                            onInputChange={(_, v) => setDescription(v)}
                            options={othersInfoStore.descriptions}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Описание"
                                    margin="normal"
                                    size="small"
                                    onKeyDown={onDescriptionKeyDown}
                                />
                            )}
                        />
                        <FocusableTextField
                            id="quantity-textfield"
                            label="Количество"
                            size="small"
                            margin="normal"
                            sx={{ minWidth: '8em' }}
                            InputProps={{
                                inputProps: { min: 1, max: 10000, type: 'number' },
                            }}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            onKeyDown={onQuantityKeyDown}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onApplyButtonClick} color="primary" variant="contained">
                        Добавить
                    </Button>
                    <Button onClick={onClose} variant="outlined">
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={error.length > 0} autoHideDuration={10000} onClose={() => setError('')}>
                <Alert severity="error" onClose={() => setError('')}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default observer(Other);
