import { TableSortLabel, styled, tableSortLabelClasses } from '@mui/material';

const StyledSortLabel = styled(TableSortLabel)(({ theme }) => ({
    [`&.${tableSortLabelClasses.root}`]: {
        color: '#c6c7c8',
        fontWeight: 700,
        '& .MuiTableSortLabel-icon': {
            color: '#c6c7c8',
        },
    },
    [`&.${tableSortLabelClasses.root}:hover`]: {
        color: theme.palette.common.white,
    },
    [`&.${tableSortLabelClasses.active}`]: {
        color: '#c6c7c8',
        fontWeight: 700,
        '& .MuiTableSortLabel-icon': {
            color: '#c6c7c8',
        },
    },
}));

export default StyledSortLabel;
