import { makeAutoObservable } from 'mobx';
import AuthService from '../services/authService';

export default class AuthStore {
    user = null;
    loading = false;

    constructor() {
        makeAutoObservable(this);
        this.refresh();
    }

    setUser(user) {
        this.user = user;
    }

    setLoading(loading) {
        this.loading = loading;
    }

    async login(login, password) {
        const response = await AuthService.login(login, password);
        // console.log('login  response:', response);
        localStorage.setItem('token', response.data.accessToken);
        this.setUser(response.data.user);
    }

    async registration(login, firstName, lastName, password) {
        const response = await AuthService.registration(login, firstName, lastName, password);
        // console.log('registration  response:', response);
        // localStorage.setItem('token', response.data.accessToken);
        // this.setUser(response.data.user);
        return response.status === 200;
    }

    async logout() {
        await AuthService.logout();
        localStorage.removeItem('token');
        this.setUser(null);
    }

    async refresh() {
        this.loading = true;
        try {
            const response = await AuthService.refresh();
            // console.log('refresh response:', response);
            localStorage.setItem('token', response.data.accessToken);
            this.setUser(response.data.user);
        } catch (e) {
            console.log('checkAuth error:', e);
        } finally {
            // this.setLoading(false);
            this.loading = false;
        }
    }
}
