import { Backdrop } from '@mui/material';

import logo from '../../img/loading.gif';
import styles from './Loading.module.css';

const Loading = () => {
    return (
        <Backdrop sx={{ backgroundColor: '#28272b' }} open={true}>
            <img className={styles.gif} src={logo} alt="Loading..." />
        </Backdrop>
    );
};

export default Loading;
