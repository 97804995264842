import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import { StyledDialogTitle } from '../StyledComponents';

/**
 * Диалог
 * @param {Boolean} open - отобразить диалог
 * @param {String} title - заголовок диалога
 * @param {String} text - текст диалога
 * @param {Function} onApply - Пользователь подтвердил действие
 * @param {Function} onClose - пользователь отверг действие
 * @returns
 */
const RemoveDialog = ({ open, title, text, onApply, onClose }) => {
    if (!open) return;

    const onKeyDown = (event) => {
        if (event.key === 'Enter') onApply();
        else if (event.key === 'Escape') onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} onKeyDown={onKeyDown}>
            <StyledDialogTitle>{title}</StyledDialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onApply} variant="contained">
                    Удалить
                </Button>
                <Button onClick={onClose} variant="outlined">
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RemoveDialog;
