import { useContext } from 'react';

import { AuthContext } from '../../index';
import OrdersAdm from './OrdersAdm';
import OrdersUsr from './OrdersUsr';

const Orders = () => {
    const { authStore } = useContext(AuthContext);

    const isAdmin = authStore.user.role === 2;

    return <>{isAdmin ? <OrdersAdm /> : <OrdersUsr />}</>;
};

export default Orders;
